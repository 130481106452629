import React from "react";
import img from "./img/food_delivery.webp";
import google from "./img/png-transparent-google-play-3.webp";
import apple from "./img/png-transparent-google-play-4.webp";
import HeaderContainer from "../../common/header-container/HeaderContainer";
import fusionpos_logo from "./img/fusionpos logo.webp";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "animate.css";

const FoodDiscoveryNetwork = () => {
  return (
    <>
      <div className="bg-[#173303] pt-1 pb-10  ">
        <HeaderContainer>
          <div className="mx-5 md:mx-10 lg:mx-14 2xl:mx-5">
            <div
              className="flex flex-col mt-6 md:mt-0 lg:mt-10
                         lg:flex-row md:gap-20"
            >
              {/* image */}
              <div
                className="mt-0 md:mt-12 lg:mt-0 lg:w-1/2 animate__animated animate__fadeInLeft"
              >
                <LazyLoadImage
                  src={img}
                  alt={"takeaway Software"}
                  title="takeaway Software"
                  className="px-0 md:px-20 xl:px-0 lg:px-0 "
                />
              </div>
              {/* content  */}
              <div
                className="lg:w-1/2 md:mt-0 flex "
              >
                <div className="flex flex-col">
                  <div className="flex justify-center md:justify-start ">
                    <LazyLoadImage
                      src={fusionpos_logo}
                      alt={"takeaway Software"}
                      title="takeaway Software"
                      className="w-1/2 rounded-lg mt-5 mb-6 text-[#9fe870] p-5 
                                 md:w-[30%]  md:mt-0  md:mb-0 md:p-0
                                 xl:p-0  xl:mt-5 xl:mb-0  "
                    />
                  </div>
                  <div
                    className="text-[#DCE2E4] text-[30px] font-bold leading-[34px] font-work-sans                          
                                md:text-[40px] md:leading-[44px] md:mt-10 
                                xl:text-[64px] xl:leading-[79px]"
                  >
                    Expand Your Reach with the Leading
                    <span className="text-[#9fe870]"> Food Discovery </span>
                    Network.
                  </div>
                  <div
                    className="text-[#DCE2E4] text-[20px] leading-[40px] font-dm-sans mt-6 mb-6
                                 lg:w-[80%] 
                                 2xl:text-[20px] 2xl:leading-[40px] 2xl:mt-6 2xl:mb-10
                             "
                  >
                    Connect with millions of food enthusiasts by listing on
                    Fusion Kitchen. Enjoy sponsored marketing campaigns and
                    exclusive partnerships – all for free.
                  </div>
                  <div className=" flex gap-6  md:w-1/2 xl:w-3/4  ">
                    <Link to="https://apps.apple.com/in/app/fusion-kitchen/id1485360740">
                      <LazyLoadImage src={google} alt={"takeaway Software"}
                        title="takeaway Software" />
                    </Link>
                    <Link to="https://play.google.com/store/search?q=fusion%20kitchen&c=apps">
                      <LazyLoadImage src={apple} alt={"takeaway Software"}
                        title="takeaway Software" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </HeaderContainer>
      </div>
    </>
  );
};

export default FoodDiscoveryNetwork;
