import React from "react";
import HeaderContainer from "../../common/header-container/HeaderContainer";
import imgLogistics from "./img/restraurents.webp";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Restaurents = () => {
  const LogisticsData = {
    headline: "FusionPOS for Restaurants",
    description1: `Restaurant owners can be pleased with FusionPOS as they have got the help to enhance their business among their competitors. Do you own a restaurant and in search of support for your techy challenges? Then you are at the right stop. If you are with FusionPOS, then influential features are at your doorstep. We offer you with your brand named attractive website with online ordering, table booking, table orders and much more features. For a restaurant that is busy with orders and bookings, someone needs to handle the orders. Our online ordering system generates a fair order report for the time duration you prefer with the number of cash/ card orders taken.`,
    description2: `You can manage your drivers with the driver screen, where you can assign the orders to drivers and even track their location in real-time. The driver report discloses the number and value of cash/card orders taken by all the drivers on duty. We help you maintain transparency in your business. Club with FusioPOS to unbox a variety of accessibilities at 0% commision and be ahead of your competitors.`,
    img: imgLogistics,
  };

  return (
    <div>
      <HeaderContainer>
        <div className="mt-[30px] mb-5 mx-[16px] md:mx-14 2xl:mx-0  ">
          <div className="flex flex-col lg:flex-row  lg:gap-5
                          xl:gap-10">
            {/* content  */}
            {
              <div
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="800"
                className="w-full order-2 lg:order-1 lg:w-1/2 "
              >
                {/* headline  */}
                <div className="">
                  <p
                    className="text-[30px] leading-[40px] text-justify hidden 
                               font-bold text-[#333333] font-work-sans capitalize
                               md:text-[36px] md:leading-[44px] md:text-start
                               lg:text-[30px] lg:block "
                  >
                    {LogisticsData.headline}
                  </p>
                </div>

                {/* title1  */}
                <div className=" xl:mt-5 mt-0 md:mt-[30px] ">
                  <p
                    className="text-[#333333] text-[16px] leading-[24px] mt-5 mb-6 font-work-sans                       
                            md:text-[20px] md:leading-[28px] font-medium                          
                           lg:text-[18px] lg:leading-[24px] text-justify
                           xl:text-[20px] xl:leading-[30px] 
                           2xl:text-[20px] 2xl:leading-[30px] 2xl:my-5 "
                  >
                    {LogisticsData.description1}
                  </p>
                </div>

                {/* title2  */}
                <div className="mt-5  md:mt-[40px] lg:mt-1 xl:mt-5">
                  <p
                    className="text-[#333333] text-[16px] leading-[28px] mt-5 mb-6 font-work-sans                       
                           md:text-[20px] md:leading-[28px] font-medium  text-justify                        
                           lg:text-[18px] lg:leading-[24px] 
                           xl:text-[20px] xl:leading-[30px] 
                           2xl:text-[20px] 2xl:leading-[30px] 2xl:my-5 "
                  >
                    {LogisticsData.description2}
                  </p>
                </div>

                {/* button  */}
                <div className="mt-5 flex justify-center md:justify-start">
                  <Link to="/contactus">
                    <button
                      className="
                   p-2.5 px-[15px]  bg-[#9fe870] text-[#173303] text-[16px] shadow-lg
                   font-semibold  rounded-[10px] font-dm-sans leading-[30px] duration-1000
                   hover:text-[#9fe870] hover:bg-[#173303]
                   "
                    >
                      Get started for free
                    </button>
                  </Link>
                </div>
              </div>
            }
            {/* image  */}

            {
              <div
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="800"
                className=" order-1  lg:order-2 lg:w-1/2"
              >
                <p
                  className="text-[30px] leading-[36px] font-bold text-[#333333]
                             font-work-sans block text-center  my-5 xl:my-0
                             md:text-[36px] md:leading-[40px] md:w-[90%] md:text-start 
                             lg:text-[40px] lg:leading-[48px]
                             lg:hidden  "
                >
                  {LogisticsData.headline}
                </p>
                <LazyLoadImage
                  src={LogisticsData.img}
                  alt="poster"
                  className="w-[100%] mb-5 md:mb-0 h-full lg:rounded-[20px]"
                />
              </div>
            }
          </div>
        </div>
      </HeaderContainer>
    </div>
  );
};

export default Restaurents;
