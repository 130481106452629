import React from "react";
import HeaderContainer from "../../common/header-container/HeaderContainer";
import { Link } from "react-router-dom";
import "./takeaways_style.css";

const Takeaways = () => {
  const restaurents = {
    headline: "FusionPOS for Takeaways",
    description: `FusionPOS supports takeaway owners to automate their takeaway at a low weekly rental. We help the takeaway owners to get busy with cooking and on-time delivery by providing a single interface where they can control everything. We offer you with an easy to use epos system along with an integrated online ordering website which comes with dynamic features. When you are with FusionPOS you can easily accept & reject orders based on your wish. If you find an order to be fake, simply reject the order and stop it from reoccurring. You can help your delivery drivers with delivery directions handed to them in a printed format and satisfy your customers by helping them track their orders. Convey your inconvenience to your customers with a late order notification. Get hold of your own customer database as marketing is of high priority in any kind of business. We are happy to disclose that FusionPOS achieves bountiful happy clients every season. Join hands with FusionPOS and untie much more vibrant features.`,
  };

  return (
    <div>
      <HeaderContainer>
        <div className="xl:mt-[30px] xl:mb-5 mx-0 md:mx-0 xl:mx-10 2xl:mx-0  ">
          <div className="">
            {/* content  */}

            <div className=" text-white takeaways_back xl:rounded-[20px]">
              <div className="overflow-hidden group md:shadow-2xl md:rounded-[20px]">
                <div className="relative">
                  <h3 className="font-work-sans capitalize items-center text-[36px]
                                 rounded-[20px] h-[650px] flex flex-col ml-6
                                 md:ml-0">
                    <p className="my-auto group-hover:-translate-y-72 duration-700 
                                  group-hover:text-[20px] text-white font-bold
                                  md:group-hover:text-[40px] 
                                  lg:group-hover:text-[44px] lg:group-hover:-translate-y-60
                                  xl:group-hover:-translate-y-56 xl:group-hover:text-[60px]"
                    >
                      {restaurents.headline}
                    </p>
                  </h3>
                  <p
                    className="font-work-sans absolute text-[14px] restaurents_small_325
                        restaurents_small_325_small_425 fixed_325_425
                        bottom-[-100%] left-0 right-0  mt-5 capitalize rounded-[20px] text-center duration-700  
                        bg-transparent h-[550px] ease-in-out group-hover:bottom-8 mx-5 transition-all                       
                        md:text-[18px] md:leading-[34px] md:h-[530px]
                        lg:leading-[30px] lg:h-[430px] lg:mx-10 lg:font-medium
                        xl:leading-[34px] xl:h-[400px] xl:font-medium
                        2xl:h-[400px]"
                  >
                    {restaurents.description}
                    <div className="mt-5 flex justify-center">
                      <Link to="/contactus">
                        <button
                          className=" btn_size
                                      p-2.5 px-[15px]  bg-[#9fe870] text-[#173303] 
                                      text-[16px] leading-[30px] shadow-lg font-semibold  rounded-[10px] 
                                      font-dm-sans  duration-1000 hover:text-[#9fe870] hover:bg-[#173303] 
                                      md:text-[16px] md:leading-[30px]
                                      lg:text-[18px] lg:leading-[30px]
                                      "
                        >
                          Get started for free
                        </button>
                      </Link>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HeaderContainer>
    </div>
  );
};

export default Takeaways;
