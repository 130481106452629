// import React from "react";
// import { Helmet } from "react-helmet";
// import { getSeoData } from "./utils/getSeoData";

// const SeoComponent = ({ pageUrl, businessTitle }) => {
  
//   const hostname = window.location.hostname;
//   const canonicalUrl = `https://${hostname}/${pageUrl}`;
//   const seoData = getSeoData(pageUrl);
//   // alert(JSON.stringify(seoData.Meta_Description));
//   console.log("Hostname:", hostname);
//   console.log("Canonical URL:", canonicalUrl);
//   // console.log("SEO Data:", seoData);
//   if (!seoData) return null;

//   return (
//     <>
//       <Helmet>
//         <style type="text/css">
//           {`
//             footer {
//               display: block;
//             }
//           `}
//         </style>
//         <link rel="canonical" href={canonicalUrl} />
//         {seoData.Meta_Title ? (
//           <title>{seoData.Meta_Title}</title>
//         ) : businessTitle ? (
//           <title>{businessTitle + " - Privacy Policy - Online Order"}</title>
//         ) : null}
//       </Helmet>
//       <Helmet>
//         <meta name="description" content={seoData.Meta_Description} />
//         <meta name="keywords" content={seoData.Keywords} />
//       </Helmet>
//     </>
//   );
// };

// export default SeoComponent;

import React from "react";
import { Helmet } from "react-helmet";
import { getSeoData } from "./utils/getSeoData";

const SeoComponent = ({ pageUrl, businessTitle }) => {
  const hostname = window.location.hostname;
  const canonicalUrl = `https://${hostname}/${pageUrl}`;
  const seoData = getSeoData(pageUrl);

  if (!seoData) return null;

  return (
    <>
      <Helmet>
        <style type="text/css">
          {`
            footer {
              display: block;
            }
          `}
        </style>
        {/* Canonical link */}
        <link rel="canonical" href={canonicalUrl} />
        
        {/* Dynamic Title */}
        {seoData.Meta_Title ? (
          <title>{seoData.Meta_Title}</title>
        ) : businessTitle ? (
          <title>{businessTitle + " - Privacy Policy - Online Order"}</title>
        ) : null}
        
        {/* Basic SEO Meta Tags */}
        <meta name="description" content={seoData.Meta_Description || ""} />
        <meta name="keywords" content={seoData.Keywords || ""} />
        <meta name="robots" content="index, follow, noydir" />
        <meta name="author" content="Fusion POS" />
        <meta name="googlebot" content="index, follow" />
        <meta name="slurp" content="index, follow" />
        <meta name="msnbot" content="index, follow" />
        <meta name="rating" content="General" />
        <meta name="revisit-after" content="2 days" />
        <meta name="distribution" content="global" />
        
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={seoData.Meta_Title || ""} />
        <meta property="og:description" content={seoData.Meta_Description || ""} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:image" content={seoData.Meta_Image ||
           "https://www.fusionpos.co.uk/static/media/fusion_pos_logo_desktop.492d74040d40911285b9.webp"} />
        <meta property="og:site_name" content="Fusion POS" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@fusion.pos" />
        <meta name="twitter:title" content={seoData.Meta_Title || ""} />
        <meta name="twitter:description" content={seoData.Meta_Description || ""} />
        <meta name="twitter:image" content={seoData.Meta_Image ||
           "https://www.fusionpos.co.uk/static/media/fusion_pos_logo_desktop.492d74040d40911285b9.webp"} />

        {/* Itemprop Image */}
        <meta itemProp="image" content={seoData.Meta_Image ||
           "https://www.fusionpos.co.uk/static/media/fusion_pos_logo_desktop.492d74040d40911285b9.webp"} />

        {/* Microsoft Specific */}
        <meta name="msapplication-TileImage" content={seoData.Meta_Image || 
          "https://www.fusionpos.co.uk/static/media/fusion_pos_logo_desktop.492d74040d40911285b9.webp"} />
      </Helmet>
    </>
  );
};

export default SeoComponent;

