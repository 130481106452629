import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import Modal from "react-modal";
import { contactUs } from "../../../redux/action/contact_us/api";
import ReactFlagsSelect from "react-flags-select";
import ReCAPTCHA from "react-google-recaptcha";
import ScheduleModal from "../../common/calendy/ScheduleModal";
import { RxCrossCircled } from "react-icons/rx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const localhost_key = process.env.REACT_APP_RECHAPTCHA_LOCALHOST_KEY;

Modal.setAppElement("#root");

const RequestDemo = ({ isOpen, onRequestClose }) => {
  const validationSchema = Yup.object().shape({
    contact_name: Yup.string().required("Name is required"),
    contact_email: Yup.string()
      .email("Email is invalid")
      .required("Email is required"),
    country_code: Yup.string().required("Country code is required"),
    contact_phone: Yup.string().required("Phone number is required"),
    contact_subject: Yup.string().required("Business name is required"),
    contact_msg: Yup.string().required("Annual revenue is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const dispatch = useDispatch();
  const [selected, setSelected] = useState("GB");
  const recaptchaRef = React.useRef();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const countryCodeMapping = {
      US: "+1",
      GB: "+44",
      AU: "+61",
      CA: "+1",
      JP: "+81",
      IE: "+353",
      ES: "+34",
      FR: "+33",
    };
    setValue("country_code", countryCodeMapping[selected]);
  }, [selected, setValue]);

  const onSubmitWithReCAPTCHA = async (data) => {
    const token = await recaptchaRef.current.executeAsync();
    if (token) {
      await dispatch(contactUs(data));
      setModalIsOpen(true);
      console.log("API called");
      reset();
    }

    notify();
  };

  const notify = () =>
    toast.success("Form is Successfully Submitted !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: "bounce",
    });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Schedule a Meeting"
      className="fixed inset-0 flex items-center px-8 justify-center bg-green-950 bg-opacity-50 z-50
                 "
      overlayClassName="Overlay"
    >
      <div className="relative bg-white rounded-lg p-5 md:p-12 w-full max-w-2xl">
        <button
          onClick={onRequestClose}
          className="absolute top-2 right-2 text-red-500 hover:text-gray-700 p-2 rounded-full"
        >
          <RxCrossCircled />
        </button>
        <div className="w-full">
          <h1 className="text-[#173303] font-bold text-[24px] leading-[30px] pr-5 md:pr-0 lg:text-[30px] lg:leading-[36px] font-work-sans mb-5">
            Connect with a FusionPOS Expert
          </h1>
          <form
            onSubmit={handleSubmit(onSubmitWithReCAPTCHA)}
            className="mb-5 border-[#333333] p- rounded-[20px] bg-white md:mb-0 md:mt-0 md:mx-auto lg:mx-0 lg:m-0"
          >
            <div className="lg:mb-5 mb-2.5">
              <input
                type="text"
                id="name"
                placeholder="Name"
                {...register("contact_name")}
                className={`shadow-sm border ${
                  errors.contact_name ? "border-red-500" : "border-[#173303]"
                } text-[16px] rounded-lg block w-full p-2 lg:p-2.5`}
              />
              {errors.contact_name && (
                <p className="text-red-500 text-sm">
                  {errors.contact_name.message}
                </p>
              )}
            </div>
            <div className="lg:mb-5 mb-2">
              <input
                type="email"
                id="email"
                {...register("contact_email")}
                className={`shadow-sm border ${
                  errors.contact_email ? "border-red-500" : "border-[#173303]"
                } text-[16px] rounded-lg block w-full p-2 lg:p-2.5`}
                placeholder="name@gmail.com"
              />
              {errors.contact_email && (
                <p className="text-red-500 text-sm">
                  {errors.contact_email.message}
                </p>
              )}
            </div>
            <div className="lg:mb-5 mb-2">
              <div className="block md:flex">
                <ReactFlagsSelect
                  selected={selected}
                  onSelect={(code) => setSelected(code)}
                  countries={["US", "GB", "AU", "CA", "JP", "IE", "ES", "FR"]}
                  customLabels={{
                    US: "USA (+1)",
                    GB: "UK (+44)",
                    AU: "Australia (+61)",
                    CA: "Canada (+1)",
                    JP: "Japan (+81)",
                    IE: "Ireland (+353)",
                    ES: "Spain (+34)",
                    FR: "France (+33)",
                  }}
                  className="mt-1.5 mr-3 text-[14px] w-full md:w-1/2"
                />
                <input
                  className=""
                  type="hidden"
                  id="country_code"
                  {...register("country_code")}
                />
                <input
                  type="text"
                  id="phone"
                  {...register("contact_phone")}
                  className={`shadow-sm border ${
                    errors.contact_phone ? "border-red-500" : "border-[#173303]"
                  } text-[16px] rounded-lg block w-full p-2 md:p-0 md:px-3`}
                  placeholder="phone number"
                />
              </div>
              {errors.contact_phone && (
                <p className="text-red-500 text-sm">
                  {errors.contact_phone.message}
                </p>
              )}
            </div>
            <div className="lg:mb-5 mb-2">
              <input
                type="text"
                id="businessName"
                {...register("contact_subject")}
                className={`shadow-sm border ${
                  errors.contact_subject ? "border-red-500" : "border-[#173303]"
                } text-[16px] rounded-lg block w-full p-2 lg:p-2.5`}
                placeholder="businessName"
              />
              {errors.contact_subject && (
                <p className="text-red-500 text-sm">
                  {errors.contact_subject.message}
                </p>
              )}
            </div>
            <div className="lg:mb-5 mb-2">
              <select
                id="annualRevenue"
                {...register("contact_msg")}
                className={`shadow-sm border ${
                  errors.contact_msg ? "border-red-500" : "border-[#173303]"
                } text-[16px] rounded-lg block w-full p-2 lg:p-2.5`}
              >
                <option value="">Select your annual revenue</option>
                <option value="less_than_100K">less than £100K</option>
                <option value="100K_250K">£100K - £250K</option>
                <option value="250K_1M">£250K - £1M</option>
                <option value="1M_5M">£1M - £5M</option>
                <option value="5M_20M">£5M - £20M</option>
              </select>
              {errors.contact_msg && (
                <p className="text-red-500 text-sm">
                  {errors.contact_msg.message}
                </p>
              )}
            </div>

            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey="6LfKnw4qAAAAAPkQScNPbWawOnpUMi244cp990ld"
              // sitekey={localhost_key}
            />
            <div className="flex justify-center mt-5">
              <button
                type="submit"
                className="block py-2 px-5 relative group font-bold ease-in-out w-full text-center bg-[#9fe870] border shadow-lg rounded-full text-nowrap hover:text-[#9fe870] hover:bg-[#173303] duration-1000"
              >
                {modalIsOpen ? "SUBMITING..." : "SUBMIT"}
              </button>
            </div>
          </form>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
            transition="Bounce"
          />
          <ScheduleModal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default RequestDemo;
