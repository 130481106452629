import React from "react";
import { FaArrowRight } from "react-icons/fa6";
import f_register from "./img/fusion_register.webp";
import square_stand from "./img/square_stand.webp";
import k_display from "./img/k_display.webp";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const FlexibleHardware = () => {
  const data = [
    {
      title: "Premium POS - Dual Screen",
      description: "£599 or £39/mo3 over 24 months",
      img: f_register,
    },
    {
      title: "Slim POS",
      description: "£299 or £14/mo.3 over 12 months",
      img: square_stand,
    },
    {
      title: "Slim card Terminal",
      description:
        "Simplify service and reduce expenses with automated customer ordering.",
      img: k_display,
    },
  ];
  return (
    <div>
      <div
        className="mx-5 max-w-[1250px] mb-10 
                   md:mb-16 md:mx-10
                   lg:mt-12
                   xl:max-w-[1140px] xl:mx-auto
                   2xl:max-w-[1250px]
                   "
      >
        <div className="">
          <h3 className="text-[#333333] text-[50px] leading-[64px] font-work-sans font-bold">
            Versatile Hardware Solutions
          </h3>
          <p className="text-[#333333] text-[20px] leading-[34px] font-dm-sans font-medium">
            Explore our full range of hardware and accessories.
          </p>
        </div>
        <div className="grid grid-cols-1 mt-10 gap-10 md:grid-cols-2 md:mt-5 lg:grid-cols-3 lg:gap-5 xl:gap-10">
          {data.map((result, index) => (
            <div
              key={index}
              className="max-w-sm bg-white border border-gray-200 shadow-lg mb-4 mx-auto hover:scale-105 duration-700 rounded-[20px]"
            >
              <div className="p-5 flex flex-col justify-center items-center ">
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-[#333333] ">
                  {result.title}
                </h5>
                <p
                  className="mb-3  text-[#333333]  w-[70%] text-center
                             text-[20px] leading-[34px] font-medium my-5"
                >
                  {result.description}
                </p>
                <LazyLoadImage className="" 
                src={result.img} 
                alt="FusionPOS cost" 
                title="FusionPOS cost" 
                
                />
                <Link to="/contactus">
                  <button
                    className=" hover:text-[#9fe870] hover:bg-[#173303]
                  text-nowrap border shadow-lg text-[#173303] bg-[#9fe870]
                  duration-1000 inline-flex items-center px-3 py-2 text-sm
                  md:text-md font-medium text-center rounded-md mt-5"
                  >
                    {" "}
                    Read more
                    <FaArrowRight className="inline ml-5" />
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FlexibleHardware;
