import React, { useState } from "react";
import logo from "./img/fusionpos logo.webp";
import google from "./img/google-play.webp";
import apple from "./img/apple-play.webp";
import HeaderContainer from "../../common/header-container/HeaderContainer";
import { FaLinkedin, FaAngleUp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IoLogoFacebook } from "react-icons/io5";
import {
  FaSquareInstagram,
  FaYoutube,
  FaXTwitter,
  FaAngleDown,
} from "react-icons/fa6";
import { LuMail } from "react-icons/lu";
import { RiPhoneFill } from "react-icons/ri";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Footer = () => {
  const [isServiceOpen, setIsServiceOpen] = useState(false);
  const [isCompanyOpen, setIsCompanyOpen] = useState(false);
  const [isResourceOpen, setIsResourceOpen] = useState(false);

  const toggleServiceDropdown = () => {
    setIsServiceOpen(!isServiceOpen);
  };

  const toggleResourceDropdown = () => {
    setIsResourceOpen(!isResourceOpen);
  };

  const toggleCompanyDropdown = () => {
    setIsCompanyOpen(!isCompanyOpen);
  };

  return (
    <>
      <div className="bg-[#173304]">
        <HeaderContainer>
          {/* desktop view  */}
          <div className="bg-[#173304]">
            <div
              className="pt-6 pb-1 font-dm-sans xl:flex  mx-auto hidden
                         xl:max-w-[1140px] 2xl:max-w-[1250px] "
            >
              {/* links */}
              <div className="links flex flex-row justify-between">
                {/* FusionPOS Icon  */}
                <div className="w-[15%] mt-2 ">
                  <LazyLoadImage
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="800"
                    src={logo}
                    alt={"best pos system for restaurant"}
                    title="best pos system for restaurant"
                    className=" ml-0 md:pe-5"
                  />
                </div>
                {/* Products & Service  */}
                <div className="">
                  <ul className="xl:text-nowrap">
                    <li className=" ">
                      <p className="font-bold  text-[20px] mb-3 text-white ">
                        {" "}
                        Products & Service
                      </p>
                      <ul className="text-white text-[16px] flex flex-col ">
                        <Link to="/best-pos-system-for-restaurants-uk" className="mt-2 text-wrap">
                          Point Of Sales
                        </Link>
                        <Link
                          to="/online-ordering-website"
                          className="mt-2 text-wrap"
                        >
                          Deliveries & Takeaways
                        </Link>
                        <Link
                          to="/restaurant-reservation-system"
                          className="mt-2 "
                        >
                          Reservation
                        </Link>
                        <Link
                          to="/qr-ordering-system-for-restaurants"
                          className="mt-2 "
                        >
                          QR Ordering
                        </Link>
                      </ul>
                    </li>
                  </ul>
                </div>

                {/* Service  */}
                <div className="">
                  <ul>
                    <li className=" ">
                      <p className="font-bold  text-[20px] text-white mb-3 invisible ">
                        {" "}
                        Products
                      </p>
                      <ul className="text-white text-[16px] z-10 flex flex-col">
                        <Link
                          to="/contactless-dining"
                          className="mt-2 text-nowrap relative "
                        >
                          Payment Terminal
                        </Link>
                        <Link
                          to="/delivery-management"
                          className="mt-2 text-nowrap relative "
                        >
                          Delivery Management
                        </Link>
                        <Link
                          to="/order-management#Preorder"
                          className="mt-2 text-nowrap relative "
                        >
                          Order Management
                        </Link>
                        <Link
                          to="/online-reservation"
                          className="mt-2 text-nowrap relative "
                        >
                          Online Reservation
                        </Link>
                      </ul>
                    </li>
                  </ul>
                </div>

                {/* Resources  */}
                <div className="">
                  <ul>
                    <li className="">
                      <p className="font-bold  text-[20px] text-white mb-3">
                        {" "}
                        Resources
                      </p>
                      <ul className="text-white text-[16px] flex flex-col">
                        <Link to="/pricing" className="mt-2 ">
                          Pricing
                        </Link>
                        <Link className="mt-2 "></Link>
                        <Link to="/blog" className="mt-2 ">
                          Blog
                        </Link>
                        <Link to="/support" className="mt-2 ">
                          Support
                        </Link>
                        <Link to="/contactus" className="mt-2 ">
                          Contact Us
                        </Link>
                        <Link to="/channel-partner" className="mt-2 ">
                          {" "}
                          Partners{" "}
                        </Link>
                        <Link to="/faq" className="mt-2 text-nowrap relative ">
                          FAQ
                        </Link>
                      </ul>
                    </li>
                  </ul>
                </div>

                {/* Company */}
                <div className="">
                  <ul>
                    <li className="">
                      <p className="font-bold  text-[20px] text-white mb-3">
                        {" "}
                        Company
                      </p>
                      <ul className="text-white text-[16px] flex flex-col">
                        <Link to="/about-us" className="mt-2 ">
                          {" "}
                          About
                        </Link>
                        <Link className="mt-2 ">Careers</Link>
                        <Link to="/areas" className="mt-2 ">
                          Areas
                        </Link>
                        <Link to="/terms-conditions" className="mt-2 ">
                          {" "}
                          Terms and Conditions{" "}
                        </Link>

                        <Link to="/delivery-terms-conditions" className="mt-2 ">
                          {" "}
                          Delivery T&C{" "}
                        </Link>
                        <Link to="/projects" className="mt-2 ">
                          {" "}
                          Restaurant & Franchises{" "}
                        </Link>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>

              {/* icons */}
              <div
                className="icons flex flex-col w-1/4 gap-2 font-work-sans
                              text-[20px] text-white border-[#333333] pl-6 "
              >
                <h2 className="font-bold text-[18px] font-work-sans ">
                  CONNECT WITH US
                </h2>
                <Link
                  to="mailto:sales@fusionpos.uk"
                  className="text-[16px] text-white cursor-pointer"
                >
                  <LuMail className="inline mr-2 rounded-full " />
                  sales@fusionpos.uk
                </Link>
                <Link
                  to="mailto:reseller@fusionmybusiness.com
"
                  className="text-[16px] text-white cursor-pointer flex items-center  "
                >
                  <LuMail className="inline mr-2 rounded-full " />
                  reseller@fusionmybusiness.com
                </Link>
                <Link
                  to="tel:02030263355"
                  className="text-[16px] text-white cursor-pointer"
                >
                  <RiPhoneFill className="inline mr-2 rounded-full" />
                  UK:
                  0203 026 3355
                </Link>
                <Link
                  to="tel:353 23 880 4757"
                  className="text-[16px] text-white cursor-pointer"
                >
                  <RiPhoneFill className="inline mr-2 rounded-full" />
                  IRE:
                  +353 23 880 4757
                </Link>
                <div className="flex gap-3 ">
                  <Link to="https://www.youtube.com/@fusionpos" target="_blank">
                    <FaYoutube
                      size={"30px"}
                      className="my-2 cursor-pointer text-white"
                    />
                  </Link>

                  <Link
                    to="https://www.instagram.com/fusion.pos/"
                    target="_blank"
                  >
                    <FaSquareInstagram
                      size={"30px"}
                      className="my-2 cursor-pointer text-white"
                    />
                  </Link>
                  <Link
                    to="https://www.facebook.com/FusionPOS/"
                    target="_blank"
                  >
                    {" "}
                    <IoLogoFacebook
                      size={"30px"}
                      className="my-2 cursor-pointer text-white"
                    />
                  </Link>
                  <Link
                    to="https://www.linkedin.com/company/fusionposuk"
                    target="_blank"
                  >
                    {" "}
                    <FaLinkedin
                      size={"30px"}
                      className="my-2 cursor-pointer text-white"
                    />
                  </Link>
                  <Link
                    to="https://x.com/i/flow/login?redirect_after_login=%2FFusion_POS"
                    target="_blank"
                  >
                    <FaXTwitter
                      size={"30px"}
                      className="my-2 cursor-pointer text-white"
                    />
                  </Link>
                </div>
                <div className=" flex  ">
                  <Link to="https://apps.apple.com/in/app/fusion-my-business/id1502807167">
                    <LazyLoadImage
                      src={google}
                      alt={"online ordering system"}
                      title="online ordering system"
                      className="w-[70%]"
                    />
                  </Link>
                  <Link to="https://play.google.com/store/apps/details?id=in.fusionpos.fusionpartner">
                    <LazyLoadImage
                      src={apple}
                      alt={"online ordering system"}
                      title="online ordering system"
                      className="w-[70%]"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* mobile view  */}
          <div
            className="overflow-y-auto flex flex-col
                         xl:hidden mx-[16px] md:mx-14 lg:mx-16"
          >
            <ul className="font-semibold text-[16px] font-work-sans w-full">
              {/* {Product 2 } */}
              <li className="relative mt-5 ">
                <div
                  onClick={toggleServiceDropdown}
                  className="p-4 cursor-pointer hover:text-white text-white flex justify-between
                   items-center border border-[#9fe870] "
                >
                  Products & Services
                  {isServiceOpen ? (
                    <FaAngleDown className="inline" />
                  ) : (
                    <FaAngleUp className="inline" />
                  )}
                </div>
                <div
                  className={`transition-max-height duration-1000 ease-in 
                              overflow-hidden  ${isServiceOpen ? "max-h-screen " : "max-h-0 "
                    }`}
                >
                  <ul className="text-white text-[14px] z-10 flex flex-col">
                    <Link
                      to="/online-ordering-website"
                      className="text-nowrap relative hover:bg-[#01c070] hover:text-white pb-2 pl-3 pt-5"
                    >
                      Deliveries & Takeaways
                    </Link>
                    <Link
                      to="/restaurant-reservation-system"
                      className="text-nowrap relative hover:bg-[#01c070] hover:text-white pb-2 pl-3 pt-5"
                    >
                      Reservation
                    </Link>
                    <Link
                      to="/qr-ordering-system-for-restaurants"
                      className="text-nowrap relative hover:bg-[#01c070] hover:text-white pb-2 pl-3 pt-5"
                    >
                      QR Ordering
                    </Link>
                    <Link
                      to="/contactless-dining"
                      className="text-nowrap relative hover:bg-[#01c070] hover:text-white pb-2 pl-3 pt-5"
                    >
                      Payment Terminal
                    </Link>
                    <Link
                      to="/point-of-sales"
                      className=" text-nowrap relative hover:bg-[#01c070] hover:text-white pb-2 pl-3 pt-5"
                    >
                      Point Of Sales
                    </Link>
                    <Link
                      to="/delivery-management"
                      className=" text-nowrap relative hover:bg-[#01c070] hover:text-white pb-2 pl-3 pt-5"
                    >
                      Delivery Management
                    </Link>
                    <Link
                      to="/order-management#Preorder"
                      className=" text-nowrap relative hover:bg-[#01c070] hover:text-white pb-2 pl-3 pt-5"
                    >
                      Order Management
                    </Link>
                    <Link
                      to="/online-reservation"
                      className=" text-nowrap relative hover:bg-[#01c070] hover:text-white pb-2 pl-3 pt-5"
                    >
                      Online Reservation
                    </Link>
                  </ul>
                </div>
              </li>

              {/* Resource  */}
              <li className="relative mt-5 ">
                <div
                  onClick={toggleResourceDropdown}
                  className="p-4 cursor-pointer hover:text-white text-white flex justify-between
                   items-center border border-[#9fe870] "
                >
                  Resource
                  {isResourceOpen ? (
                    <FaAngleDown className="inline" />
                  ) : (
                    <FaAngleUp className="inline" />
                  )}
                </div>
                <div
                  className={`transition-max-height duration-1000 ease-in overflow-hidden ${isResourceOpen ? "max-h-screen " : "max-h-0 "
                    }`}
                >
                  <ul className="text-white text-[14px] z-10 flex flex-col ">
                    <Link
                      to="/pricing"
                      className=" text-nowrap relative hover:bg-[#01c070] hover:text-white pl-3 py-2"
                    >
                      Pricing
                    </Link>
                    <Link
                      to="/blog"
                      className=" text-nowrap relative hover:bg-[#01c070] hover:text-white pl-3 py-2"
                    >
                      Blog
                    </Link>
                    <Link
                      to="/support"
                      className=" text-nowrap relative hover:bg-[#01c070] hover:text-white pl-3 py-2"
                    >
                      Support
                    </Link>
                    <Link
                      to="/channel-partner"
                      className="p-2.5 text-nowrap relative hover:bg-[#01c070] hover:text-white "
                    >
                      Partners
                    </Link>
                    <Link
                      to="/contactus"
                      className=" text-nowrap relative hover:bg-[#01c070] hover:text-white pl-3 py-2"
                    >
                      Contact Us
                    </Link>
                  </ul>
                </div>
              </li>

              {/* Company  */}
              <li className="relative mt-5 ">
                <div
                  onClick={toggleCompanyDropdown}
                  className="p-4 cursor-pointer hover:text-white text-white flex justify-between
                   items-center border border-[#9fe870] "
                >
                  Company
                  {isCompanyOpen ? (
                    <FaAngleDown className="inline" />
                  ) : (
                    <FaAngleUp className="inline" />
                  )}
                </div>
                <div
                  className={`transition-max-height duration-1000 ease-in overflow-hidden mt-5 ${isCompanyOpen ? "max-h-screen " : "max-h-0 "
                    }`}
                >
                  <ul className="text-white text-[14px] z-10  flex flex-col">
                    <Link
                      to="/about-us"
                      className="p-2.5 text-nowrap relative hover:bg-[#01c070] hover:text-white"
                    >
                      About
                    </Link>
                    <Link className="p-2.5 text-nowrap relative hover:bg-[#01c070] hover:text-white">
                      Careers
                    </Link>
                    <Link
                      to="/areas"
                      className="p-2.5 text-nowrap relative hover:bg-[#01c070] hover:text-white"
                    >
                      Areas
                    </Link>
                    <Link
                      to="/terms-conditions"
                      className="p-2.5 text-nowrap relative hover:bg-[#01c070] hover:text-white"
                    >
                      Terms and Conditions
                    </Link>

                    <Link
                      to="/delivery-terms-conditions"
                      className="p-2.5 text-nowrap relative hover:bg-[#01c070] hover:text-white "
                    >
                      Delivery T&C
                    </Link>
                    <Link to="/projects" className="mt-2 ">
                      {" "}
                      Restaurant & Franchises{" "}
                    </Link>
                  </ul>
                </div>
              </li>
            </ul>
            <div className="icons flex flex-col mt-0 gap-2 font-work-sans text-[20px] text-white border-[#333333] px-[16px] ">
              <h2 className="font-bold text-[18px] ">CONNECT WITH US</h2>
              <Link
                to="mailto:sales@fusionpos.uk"
                className="text-[16px] text-white cursor-pointer"
              >
                <LuMail className="inline mr-2 rounded-full " />
                sales@fusionpos.uk
              </Link>
              <Link
                to="mailto:reseller@fusionmybusiness.com"
                className="text-[16px] text-white cursor-pointer"
              >
                <LuMail className="inline mr-2 rounded-full " />
                reseller@fusionmybusiness.com
              </Link>
              <Link
                to="tel:02030263355"
                className="text-[16px] text-white cursor-pointer"
              >
                <RiPhoneFill className="inline mr-2 rounded-full" />
                UK:
                0203 026 3355
              </Link>
              <Link
                to="tel:353238804757"
                className="text-[16px] text-white cursor-pointer"
              >
                <RiPhoneFill className="inline mr-2 rounded-full" />
                IRE:
                +353 23 880 4757
              </Link>
              <div className="flex gap-3 ">
                <Link to="https://www.youtube.com/@fusionpos" target="_blank">
                  <FaYoutube
                    size={"30px"}
                    className="my-2 cursor-pointer text-white"
                  />
                </Link>
                <Link
                  to="https://x.com/i/flow/login?redirect_after_login=%2FFusion_POS"
                  target="_blank"
                >
                  <FaXTwitter
                    size={"30px"}
                    className="my-2 cursor-pointer text-white"
                  />
                </Link>
                <Link
                  to="https://www.instagram.com/fusion.pos/"
                  target="_blank"
                >
                  <FaSquareInstagram
                    size={"30px"}
                    className="my-2 cursor-pointer text-white"
                  />
                </Link>
                <Link to="https://www.facebook.com/FusionPOS/" target="_blank">
                  {" "}
                  <IoLogoFacebook
                    size={"30px"}
                    className="my-2 cursor-pointer text-white"
                  />
                </Link>
                <Link
                  to="https://www.linkedin.com/company/fusionposuk"
                  target="_blank"
                >
                  {" "}
                  <FaLinkedin
                    size={"30px"}
                    className="my-2 cursor-pointer text-white"
                  />
                </Link>
              </div>
              <div className="flex justify-start w-1/2  gap-2 lg:gap-0">
                <Link to="https://apps.apple.com/in/app/fusion-my-business/id1502807167">
                  <LazyLoadImage
                    src={google}
                    alt={"takeaway pos software"}
                    title="takeaway pos software"
                    className="lg:w-[60%] w-full"
                  />
                </Link>
                <Link to="https://play.google.com/store/apps/details?id=in.fusionpos.fusionpartner">
                  <LazyLoadImage
                    src={apple}
                    alt={"best pos system for restaurant"}
                    title="best pos system for restaurant"
                    className="lg:w-[60%] w-full"
                  />
                </Link>
              </div>
            </div>
          </div>
        </HeaderContainer>
        {/* Terms & condition  */}
        <div className=" pb-5 mx-0  md:mt-5 mt-5 bg-[#1e5017] py-5">
          <HeaderContainer>
            <div
              className="flex flex-col items-center justify-center
                    font-semibold text-white text-[13px] sm:text-[14px] space-y-4                    
                    md:space-y-0  lg:flex-row  lg:mx-auto"
            >
              <div
                className="w-full text-nowrap gap-2 flex flex-col text-center
                           md:w-1/2 md:flex md:flex-row lg:w-1/2"
              >
                <p> © 2024 | Fusion Innovative Limited T/A FusionPOS </p>
                <span className="md:inline hidden">|</span>
                <Link to="/privacy-and-policy" className="md:inline">
                  Privacy Policy - GDPR
                </Link>
              </div>
            </div>
          </HeaderContainer>
        </div>
      </div>
    </>
  );
};

export default Footer;
