import React from "react";
import HeaderContainer from "../../common/header-container/HeaderContainer";
import "./style.css";

const Vision = () => {
  const vision_right = {
    title: "VISION",
    description: `To provide a cloud- based EPOS system with world
    class quality and service to our clients- whenever they
    need, wherever they need.`,
  };
  const vision_left = {
    title: "MISSION",
    description: `We at FusionPOS intend to provide a fully integrated
    advanced EPOS system solely for the hospitality industry to create the
    best possible experience for our clients and their customers. A system that
    is affordable, reliable, packed with unique features, and offers top-
    notch technical and customer service that makes them run their business efficiently.`,
  };

  return (
    <div>
      <HeaderContainer>
        <div
          className="mt-5 mb-5 mx-[16px]  text-white
                     md:mt-28 md:mx-16
                     lg:mx-16  lg:mt-12
                     xl:mx-12
                     2xl:mx-0 "
        >
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 xl:gap-20">
            {/*Left image  */}
            <div className=" text-white">
              <div className="overflow-hidden group shadow-2xl rounded-[20px]">
                <div className="relative">
                  <h3
                    className="font-work-sans capitalize items-center text-[36px]
                              bg-gradient-to-r from-[#173304] to-[#24940B]
                              rounded-[20px] h-[380px] flex flex-col justify-start 
                              md:h-[450px]"
                  >
                    <p
                      className="my-auto group-hover:-translate-y-40 duration-700 
                                group-hover:text-[30px] text-[#49ff52] font-bold
                                md:group-hover:text-[40px]
                                lg:group-hover:-translate-y-48
                                xl:group-hover:-translate-y-40 xl:group-hover:text-[60px] "
                    >
                      {vision_left.title}
                    </p>
                  </h3>
                  <p
                    className="font-work-sans absolute text-[18px] small_325 small_425 fixed_325_425
                     bottom-[-100%] left-0 right-0  mt-5 capitalize rounded-[20px] text-center duration-700
                     bg-transparent h-[300px] ease-in-out group-hover:bottom-8 mx-5 transition-all                       
                     md:mx-10 md:text-[24px] md:leading-[34px]
                     lg:text-[20px] lg:leading-[30px] lg:h-[350px]
                     2xl:text-[24px] 2xl:leading-[34px] xl:h-[300px]"
                  >
                    {vision_left.description}
                  </p>
                </div>
              </div>
            </div>

            {/*Right content  */}
            <div className="overflow-hidden group shadow-2xl rounded-[20px]">
              <div className="relative">
                <h3
                  className="font-work-sans capitalize bg-gradient-to-r from-[#24940B] to-[#173304]
                             rounded-[20px] h-[450px] flex flex-col justify-start 
                             items-center text-[36px] height_right"
                >
                  <p
                    className="my-auto group-hover:-translate-y-24 duration-700 
                               group-hover:text-[30px]
                               xl:group-hover:text-[60px] text-[#49ff52] font-bold"
                  >
                    {vision_right.title}
                  </p>
                </h3>
                <p
                  className="text-[20px] leading-[24px]  font-work-sans absolute
                  bottom-[-90%] left-0 right-0  mt-5 capitalize rounded-[20px] text-center
                  bg-transparent h-[250px] flex flex-col justify-center items-center
                  transition-all duration-700 ease-in-out group-hover:bottom-10 mx-10
                  md:text-[24px] md:leading-[34px]
                  lg:text-[20px] lg:leading-[30px] lg:h-[280px]
                  2xl:text-[24px] 2xl:leading-[34px] "
                >
                  {vision_right.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </HeaderContainer>
    </div>
  );
};

export default Vision;
