import React, { useState, useRef, useEffect } from "react";
import HeaderContainer from "../header-container/HeaderContainer";
import SeoComponent from "../../../SeoComponent";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const refs = useRef([]);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    if (activeIndex !== null && refs.current[activeIndex]) {
      refs.current[activeIndex].scrollIntoView({ behavior: "smooth" });
    }
  }, [activeIndex]);

  const faq_contenet = [
    {
      question: "How do I get started with Fusion POS?",
      answer:
        "Getting started is easy! You can contact us for a free demo or consultation to see how Fusion POS works for your restaurant. Our team will guide you through setup and ensure the system is tailored to your specific requirements. ",
    },
    {
      question: "How long does the Fusion POS System take to set up?",
      answer:
        "The lead time for the Fusion POS System is approximately 2 weeks. A lot of the time we can do this much quicker.",
    },
    {
      question: "Do you come to our shop to install the Fusion POS Systems?",
      answer:
        "The system is so simple to use that you can set it up in minutes, meaning you can get up and running as soon as possible. Why wait for a technician to come and install your hardware. All you need to do is plug a power cable in and an internet cable. Then switch it on, once this is done give us a call and we’ll do the rest.",
    },
    {
      question: "What hardware do you use?",
      answer:
        "With the Fusion POS System or Online Ordering System, we supply you with an All-in-One Touch screen, Thermal Receipt Printer and a Caller ID Modem (Caller ID still needs to be enabled on the line). Cash Draw can be purchased at the extra cost of £75.00. Additional thermal receipt printer can be purchased at £250.00 each printer.",
    },
    {
      question: "How secure is Fusion POS for handling customer payments?",
      answer:
        "Fusion POS is highly secure, following industry standards and compliance regulations to protect customer data and payment information. We use encryption and other advanced security measures to ensure your transactions are safe. ",
    },
    {
      question: "How do we get started?",
      answer:
        "It’s simple. All you need to do is call our Sales team at Sales: (+44)203 026 3355",
    },
    {
      question: "How do the text messages work?",
      answer:
        "You can send customised messages to customers in your customer database. There are also samples available for you to use. The system will tell you how much these texts are going to cost.",
    },
    {
      question:
        "If I have a website or menu change how quickly can you do this?",
      answer:
        "If you need a simple website change it can be done the same business day. If it is a complete website or menu update then it could take up to 4 business days but a lot of the time we can do this for you in 48 hours.",
    },
    {
      question: "Which Merchant Account company do we use?",
      answer:
        "We use Nochex. They accept all the major card companies and offer very competitive rates. Please visit http://www.nochex.com to know about their policies.",
    },
    {
      question: "Why is Fusion POS the best POS system for restaurants in the UK?",
      answer:
        "Fusion POS is designed with UK restaurants in mind, offering features like compliance with UK regulations, multi-location management, inventory tracking, and robust customer support. Our system helps reduce wait times, improves order management, and provides detailed sales reports, making it a top choice for UK restaurant owners.",
    },


  ];
  return (
    <HeaderContainer>
      <SeoComponent pageUrl="faq" businessTitle="FusionPOS" />
      <div className="accordion-group mt-10 font-work-sans mx-5 md:mx-12 2xl:mx-0">
        <div>
          <h3 className="text-[24px]  md:text-[36px] font-bold font-work-sans my-10 ml-2">
            How can we help?
          </h3>
        </div>
        {faq_contenet.map((item, index) => (
          <div
            key={index}
            ref={(el) => (refs.current[index] = el)}
            className={`accordion border border-solid  p-4 rounded-xl mb-8 shadow-md transition duration-700 hover:shadow-2xl hover:bg-slate-100 ${activeIndex === index
              ? "accordion-active:bg-indigo-50 accordion-active:border-indigo-600 bg-green-100"
              : ""
              }`}
            id={`basic-heading-${index}-with-icon`}
          >
            <button
              className="accordion-toggle group inline-flex items-center justify-between text-left text-base leading-8 text-gray-900 w-full transition duration-500 hover:text-green-600"
              aria-controls={`basic-collapse-${index}-with-icon`}
              onClick={() => toggleAccordion(index)}
            >
              <h5 className="font-semibold text-[12px] sm:text-[12px] md:text-[16px] ">{item.question}</h5>
              <svg
                className={`w-6 h-6 transition duration-500 ${activeIndex === index ? "hidden" : "block"
                  } group-hover:text-green-600`}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 12H18M12 18V6"
                  stroke="currentColor"
                  strokeWidth="1.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
              <svg
                className={`w-6 h-6 transition duration-500 ${activeIndex === index ? "block" : "hidden"
                  } group-hover:text-green-600`}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 12H18"
                  stroke="currentColor"
                  strokeWidth="1.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </button>
            <div
              id={`basic-collapse-${index}-with-icon`}
              className={`accordion-content w-full overflow-hidden pr-4 transition duration-500 ${activeIndex === index ? "max-h-full" : "max-h-0"
                }`}
              aria-labelledby={`basic-heading-${index}-with-icon`}
            >
              <p className="text-base text-gray-900 leading-6 mt-10 font-dm-sans text-[16px] sm:text-[18px] md:text-[16px]">
                {item.answer}
              </p>
            </div>
          </div>
        ))}
      </div>
    </HeaderContainer>
  );
};

export default FAQ;
