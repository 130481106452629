import axios from "axios";
import { contactUsSuccess, contactUsFailure } from "./actionType";
import { CONTACT_US } from "..";


export const contactUs = (formData) => {
  return (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    return axios.post(CONTACT_US, formData, config)
      .then((response) => {
        console.log("Response in API:", response);
        dispatch(contactUsSuccess(response.data));
      })
      .catch((error) => {
        console.error("Error:", error);
        dispatch(contactUsFailure(error.message));
      });
  };
};
