import { SUBMIT_CONTACT_FORM_FAILURE, SUBMIT_CONTACT_FORM_SUCCESS } from "../../action/contact_us/actionType";

  
  const initialState = {
    // loading: false,
    // success: false,
    // error: null,
    inputData:null
  };
  
  const contactReducer = (state = initialState, action) => {
    switch (action.type) {
      case SUBMIT_CONTACT_FORM_SUCCESS:
        return { ...state ,inputData:action.payload };
      case SUBMIT_CONTACT_FORM_FAILURE:
        return { ...state ,inputData:action.payload };
      default:
        return state;
    }
  };
  
  export default contactReducer;
  