import React from "react";
import HeaderContainer from "../../common/header-container/HeaderContainer";
import { Link } from "react-router-dom";
import "./franchise_style.css";

const Takeaways = () => {
  const restaurents = {
    headline: "FusionPOS for Franchise",
    description: `In order to sustain and build profitability, as franchisers you should be aware of your operations. Every franchise holder must ensure that their operations are working efficiently enough. FusionPOS helps Franchisees to become more beneficial by automating time consuming order management thus helping them spend more time in planning and rendering quality assurance. We offer them with an exclusive online ordering system that can generate specific order reports for each franchise.A call centre setup helps you to accept & reject orders from any of your franchise, thus handling them in their hardships. Using the boss login account, you can have access to any branch and take control over other login accounts. Hence with FusionPOS, you have total flexibility to multiple locations. Join our happy clients and get your solutions from FusionPOS.`,
  };

  return (
    <div>
      <HeaderContainer>
        <div className="xl:mt-[30px] xl:mb-5 mx-0 md:mx-0 xl:mx-10 2xl:mx-0  ">
          <div className="">
            {/* content  */}

            <div className=" text-white franchise_back xl:rounded-[20px] ">
              <div className="overflow-hidden group md:shadow-2xl md:rounded-[20px] ">
                <div className="relative md:mx-10">
                  <h3
                    className="font-work-sans capitalize items-center text-[36px]
                                 rounded-[20px] h-[650px] flex flex-col ml-3
                                 md:ml-0"
                  >
                    <p
                      className="my-auto group-hover:-translate-y-64 duration-700 
                                  group-hover:text-[20px] text-white font-bold
                                  md:group-hover:text-[40px] 
                                  lg:group-hover:text-[44px] lg:group-hover:-translate-y-60
                                  xl:group-hover:-translate-y-56 xl:group-hover:text-[60px]"
                    >
                      {restaurents.headline}
                    </p>
                  </h3>
                  <p
                    className="font-work-sans absolute text-[14px] franchise_small_325
                        franchise_small_325_small_425 franchise_fixed_325_425
                        bottom-[-100%] left-0 right-0  mt-5 capitalize rounded-[20px] text-center duration-700  
                        bg-transparent h-[550px] ease-in-out group-hover:bottom-8 mx-5 transition-all                       
                        md:text-[18px] md:leading-[34px] md:h-[500px]
                        lg:text-[20px] lg:leading-[34px] lg:h-[460px] lg:mx-10 lg:font-medium
                        xl:text-[20px] xl:leading-[34px] xl:h-[430px] xl:font-medium
                        2xl:h-[400px]"
                  >
                    {restaurents.description}
                    <div className="mt-5 flex justify-center">
                      <Link to="/contactus">
                        <button
                          className=" btn_size
                                      p-2.5 px-[15px]  bg-[#9fe870] text-[#173303] 
                                      text-[16px] leading-[30px] shadow-lg font-semibold rounded-[10px] 
                                      font-dm-sans  duration-1000 hover:text-[#9fe870] hover:bg-[#173303] 
                                      md:text-[16px] md:leading-[30px]
                                      lg:text-[18px] lg:leading-[30px] "
                        >
                          Get started for free
                        </button>
                      </Link>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HeaderContainer>
    </div>
  );
};

export default Takeaways;
