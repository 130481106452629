import React from "react";
import PrimeComponent from "../components/restaurent_franchises/prime_component/PrimeComponent";
import Navbar from "../components/common/navbar/Navbar";
import Footer from "../components/common/footer/Footer";
import ChatIcon from "../components/common/chat/ChatIcon";
import ScrollToTop from "../components/common/scroll-icon/ScrollToTop";
import GoToTop from "../components/common/scroll-icon/GoToTop";
import Takeaways from "../components/restaurent_franchises/takeaways/Takeaways";
import Restaurents from "../components/restaurent_franchises/restaurents/Restaurents";
import Franchise from "../components/restaurent_franchises/franchise/Franchise";

const RestaurantFranchises = () => {
  return (
    <div>
      <Navbar />
      <GoToTop />
      <PrimeComponent />
      <Takeaways />
      <Restaurents />
      <Franchise />
      <ChatIcon />
      <ScrollToTop />
      <Footer />
    </div>
  );
};

export default RestaurantFranchises;
