import React from "react";
import HeaderContainer from "../../common/header-container/HeaderContainer";
import imgRight from "./img/personal_client_success_manager.webp";
import imgLeft from "./img/marketing_services_management.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ExtraIngredients = () => {
  return (
    <>
      <HeaderContainer>
        <div className="mx-5  md:mx-6 lg:mx-10 2xl:mx-0">
          <div
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="800"
            className="mt-16 mb-10 mx-5 flex flex-col justify-center items-center 2xl:mx-0"
          >
            <p
              className="text-[30px] leading-[36px]
                         text-[#333333] font-bold text-center
                           md:text-[48px] md:leading-[63px]
                           lg:w-[65%] xl:w-[45%]"
            >
              The Added Ingredients for Your Success.
            </p>
            <p
              className="text-[20px] leading-[28px] text-center mt-5 
                         font-semibold
                         md:text-[24px] md:leading-[34px]
                         2xl:text-[24px] 2xl:leading-[34px] text-[#333333]"
            >
              Additional services at no cost. All to boost your sales together.
            </p>
            <h2 className="md:text-[32px] md:leading-[44px] text-[30px] text-[#333333] font-bold text-center mt-5">
              We're Here to Support You.
            </h2>
          </div>
          <div className="flex flex-col mt-16  lg:flex-row gap-10 2xl:gap-16 ">
            {/* image Left */}
            <div
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="800"
              className="   lg:w-1/2 2xl:m-0"
            >              
              <div>
                <LazyLoadImage
                  src={imgLeft}
                  alt={"Kiosk Software "}
                  title="Kiosk Software "
                  className="border rounded-[30px] "
                />
                <div className="mt-6 mb-6 ">
                  <p className="text-[24px] leading-[28px] font-bold text-center text-[#333333]">
                    Personal Client Success Manager
                  </p>
                </div>
                <div className="flex items-center justify-center">
                  <p
                    className=" text-[20px] leading-[28px] text-center text-[#333333]
                                    md:leading-[36px] font-semibold lg:w-[80%] "
                  >
                    Serving as your F&B consultant to help you make the most of
                    your restaurant software, connect with your top customers,
                    and increase your sales.
                  </p>
                </div>
              </div>
            </div>

            {/* image Right */}
            <div
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="800"
              className=" lg:w-1/2 2xl:m-0 "
            >
              <div>
                <div>
                  <LazyLoadImage
                    src={imgRight}
                    alt={"Retail POS software"}
                    title="Retail POS software"
                    className="border rounded-[30px] "
                  />
                  <div className="mt-6 mb-6 ">
                    <p className="text-[24px] leading-[28px] font-bold text-center text-[#333333]">
                      Marketing Services Management
                    </p>
                  </div>
                  <div className="flex items-center justify-center">
                    <p
                      className=" text-[20px] leading-[28px] text-center text-[#333333]
                                    md:leading-[36px] font-semibold
                                    lg:w-[80%]
                                    "
                    >
                      We extend your marketing team with social media
                      advertising and email marketing, from design to execution.
                      Let us handle it for you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HeaderContainer>
    </>
  );
};

export default ExtraIngredients;
