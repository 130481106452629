import { useState } from "react";
import { Link } from "react-router-dom";
import "animate.css";
// import "./style.css";
import HeaderContainer from "../../common/header-container/HeaderContainer";
import banner_man from "./img/banner_man.webp";
import delivery from "./img/delivery.webp";
import online_active from "./img/online_active.webp";
import online_active_bar from "./img/online_active_bar.webp";
import qr from "./img/qr.webp";
import terminal from "./img/terminal.webp";
import tick from "./img/tick.webp";
import pizza from "./img/pizza.webp";
import RequestDemo from "../../common/request_demo_form/RequestDemo";

const Fusion_POS = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const callForm = () => {
    setModalIsOpen(true);
  };

  return (
    <div>
      <div
        className="lg:flex lg:flex-col lg:justify-center lg:items-center
                   lg:bg-gradient-to-br from-[#cdffad] from-10% via-white via-30% to-white  to-90% "
      >
        <HeaderContainer>
          <div
            className="mx-5
                     md:mx-12 md:mt-2
                     lg:mx-14 lg:mt-24
                     xl:mt-28 xl:mb-24 xl:mx-0
                     2xl:mx-0 2xl:mt-24 2xl:mb-12"
          >
            <div
              className="flex flex-col pt-6
                         md:mt-2 
                         lg:flex-row lg:mt-12 "
            >
              {/* content  */}
              <div
                className="mt-8 flex justify-end items-center    
                           lg:w-1/2 lg:flex lg:justify-center lg:items-start lg:mt-4
                           2xl:mt-10 animate__animated animate__zoomIn"
              >
                <div className="flex flex-col">
                  <h1
                    className="text-[#333333]  text-[26px] 
                                font-bold leading-[36px] font-work-sans                           
                          md:text-[40px] md:leading-[48px] capitalize    
                          lg:leading-[40px]                      
                          xl:text-[36px] xl:leading-[40px] "
                  >
                    <span
                      className="text-[36px] leading-[40px]
                                 md:text-[50px] md:leading-[60px]
                                 lg:text-[40px] lg:leading-[50px]
                                 xl:text-[50px] xl:leading-[65px]
                                 2xl:text-[65px] 2xl:font-bold 2xl:leading-[74px] 2xl:w-[90%] block xl:inline"
                    >
                      Your Brand, <br /> Your Business
                    </span>
                    <span
                      className="text-[#83d94e]  
                                 lg:text-[30px] lg:leading-[36px]
                                 xl:text-[36px] xl:leading-[45px] xl:block 
                                 2xl:inline 2xl:text-[45px] 2xl:font-bold 2xl:leading-[64px]"
                    >
                      {" "}
                      All-in-One Restaurant <br className="block xl:hidden" />{" "}
                      POS Software Solution
                    </span>{" "}
                  </h1>
                  <p
                    className="text-[#333333] text-[18px] leading-[24px] mt-6 mb-6 font-dm-sans                          
                           md:w-[90%] md:text-[20px] md:leading-[28px] font-medium
                           lg:text-[20px] lg:leading-[40px] lg:w-[85%]
                           xl:text-[20px] xl:leading-[40px] 
                           2xl:text-[22px] 2xl:leading-[40px] 2xl:mt-10 2xl:mb-10 2xl:w-[75%] "
                  >
                    Manages all your restaurant operations efficiently so that
                    you can focus on growing your brand, like a real boss!
                  </p>
                  <div
                    className="flex very-small-device justify-start gap-5
                         md:gap-5  md:justify-start"
                  >
                    <button
                      onClick={callForm}
                      className="hover:text-[#9fe870] hover:bg-[#173303] text-nowrap shadow-lg
                           bg-[#9fe870] text-[#173303] rounded-[10px]  duration-1000
                            font-bold font-work-sans text-[16px] leading-[30px] px-[10px] py-[5px]
                            md:text-[20px] md:leading-[32px] md:py-[12px] md:px-[16px]
                            lg:text-[18px] lg:leading-[24px] lg:py-[10px] lg:px-[14px]
                            xl:text-[20px] xl:leading-[30px]  
                            2xl:py-[12px] 2xl:px-[16px]"
                    >
                      Free Trial Today
                    </button>

                    <Link to="/contactus">
                      <button
                        className="hover:text-[#9fe870] hover:bg-[#173303] text-nowrap border shadow-lg
                           bg-white border-[#9fe870] text-[#173303] rounded-[10px] 
                            font-bold  duration-1000  font-work-sans                          
                            text-[16px] leading-[30px] px-[10px] py-[5px]                   
                            md:text-[20px] md:leading-[32px] md:py-[12px] md:px-[16px] 
                            lg:text-[18px] lg:leading-[24px] lg:py-[10px] lg:px-[14px]
                            xl:text-[20px] xl:leading-[30px] 
                            2xl:py-[12px] 2xl:px-[16px]"
                      >
                        Contact Sales
                      </button>
                    </Link>
                    <RequestDemo
                      isOpen={modalIsOpen}
                      onRequestClose={() => setModalIsOpen(false)}
                    />
                  </div>
                </div>
              </div>

              {/* image */}
              <div
                className="order-1 mt-8 mb-20 relative animate__animated animate__zoomIn
                           lg:w-1/2  lg:mt-0
                           2xl:mt-0  "
              >
                <img
                  src={banner_man}
                  className="absolute "
                  alt="takeaway pos software"
                  title="takeaway pos software"
                />
                <img
                  src={online_active}
                  alt="takeaway pos software"
                  title="takeaway pos software"
                  className="absolute -top-2 -left-1 w-[25%]                             
                             md:top-0 md:-left-1 md:w-[25%]
                             lg:-top-0 lg:-left-10 lg:w-[30%]
                             xl:-top-0 xl:-left-10 xl:w-[30%]                           
                             animate__animated  animate__fadeInTopLeft animate__delay-1s "
                />
                <img
                  src={pizza}
                  alt="takeaway pos software"
                  title="takeaway pos software"
                  className="absolute top-28 -left-2 w-[30%]
                             md:top-[240px] md:left-0 md:w-[25%]
                             lg:top-[160px] lg:-left-10 lg:w-[25%]
                             xl:top-[200px] xl:-left-10 xl:w-[30%]     
                             2xl:top-[220px] 2xl:-left-10 2xl:w-[30%]                      
                             animate__animated  animate__fadeInTopLeft animate__delay-1s "
                />
                <img
                  src={delivery}
                  alt="takeaway pos software"
                  title="takeaway pos software"
                  className="absolute left-[140px] w-[25%]
                             md:top-0 md:left-[250px] md:w-[25%]
                             lg:-top-0 lg:left-[180px] lg:w-[20%]
                             xl:-top-0 xl:left-[200px] xl:w-[25%]    
                             2xl:-top-0 2xl:left-[240px] 2xl:w-[25%]                      
                             animate__animated  animate__slideInDown animate__delay-2s"
                />
                <img
                  src={terminal}
                  alt="takeaway pos software"
                  title="takeaway pos software"
                  className="absolute -right-4  w-[25%]
                             lg:-top-0 lg:-right-4 lg:w-[20%]
                             xl:-top-0 xl:-right-4 xl:w-[25%]
                             animate__animated  animate__fadeInTopRight animate__delay-1s "
                />
                <img
                  src={qr}
                  alt="takeaway pos software"
                  title="takeaway pos software"
                  className="absolute top-32 -right-4 w-[25%]
                             md:top-[260px] md:-right-4 md:w-[20%]
                             lg:top-44 lg:-right-4 lg:w-[20%]
                             xl:top-48 xl:-right-4 xl:w-[25%]
                             2xl:top-56 2xl:-right-4 2xl:w-[25%]
                             animate__animated animate__zoomIn animate__delay-1s "
                />
                <img
                  src={online_active_bar}
                  alt="takeaway pos software"
                  title="takeaway pos software"
                  className="absolute top-[270px] right-0 w-[25%]
                             md:-right-4 md:w-[30%] md:top-[480px]
                           lg:-right-4 lg:w-[30%] lg:top-[320px]
                           xl:-right-4 xl:w-[35%] xl:top-[350px]
                           2xl:-right-4 2xl:w-[30%] 2xl:top-[450px]
                           animate__animated animate__zoomIn animate__delay-1s "
                />
                <img
                  src={tick}
                  alt="takeaway pos software"
                  title="takeaway pos software"
                  className="absolute top-[270px] -left-2 w-[35%]
                           md:-right-4 md:w-[30%] md:top-[500px]
                           lg:-right-4 lg:w-[30%] lg:top-[350px]
                           xl:-right-5 xl:w-[35%] xl:top-[390px]
                           2xl:-right-4 2xl:w-[30%] 2xl:top-[480px]
                           animate__animated animate__zoomIn animate__delay-1s"
                />
              </div>
            </div>
          </div>
        </HeaderContainer>
      </div>
    </div>
  );
};

export default Fusion_POS;
