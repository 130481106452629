import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import img from "./img/restaurants-online-ordering-system.jpg";
import "./style.css";

const R_F_PrimeComponent = () => {
  return (
    <>
      <div className="restaurent_prime_back">
        <div
          className="pt-10 flex justify-center flex-col items-center
                     md:py-28 md:pb-16
                     lg:pt-28 
                     xl:pt-40"
        >
          <h1 className="
                      text-[40px] leading-[60px] font-work-sans font-bold  mt-16 text-white text-center mx-5
                      md:text-[60px] md:leading-[64px]
                      lg:text-[70px] lg:leading-[94px] lg:mx-10
                      xl:pt-0
                      2xl:text-[80px] 2xl:pt-24
                      "
          >
            A Complete Online Ordering System
          </h1>
          <p
            className="
                     text-[20px] leading-[30px] font-work-sans font-semibold text-center mx-10 mt-10 text-white
                     md:text-[24px] md:leading-[34px] md:mx-20
                     lg:text-[36px] lg:leading-[54px]  lg:mt-20 xl:mx-32
                     2xl:w-[50%]
                     "
          >
            A rich feature set designed to help you sell more and give you
            complete control.
          </p>
          <LazyLoadImage src={img} alt="Chef" className="invisible h-[450px]" />
        </div>
      </div>
    </>
  );
};

export default R_F_PrimeComponent;
