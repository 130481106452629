import React, { useState, useRef } from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import GoToTop from "../scroll-icon/GoToTop";
import ScrollToTop from "../scroll-icon/ScrollToTop";
import ChatIcon from "../chat/ChatIcon";
import SeoComponent from "../../../SeoComponent";

const TermsAndConditions = () => {
  const [openSection, setOpenSection] = useState(null);

  const accordionRefs = {
    BasisOfContract: useRef(null),
    Introduction: useRef(null),
    PurchasingProduct: useRef(null),
    InternetRequirement: useRef(null),
    ChargesRental: useRef(null),
    TermsAboutCancellation: useRef(null),
    CodeofConduct: useRef(null),
    FreeTrialPeriod: useRef(null),
    PaymentsFPOS: useRef(null),
    IAT: useRef(null),
    PaymentGateway: useRef(null),
    SendSMS: useRef(null),
    FailureRent: useRef(null),
    ReturnPolicy: useRef(null),
    RiskInsurance: useRef(null),
    LawJurisdiction: useRef(null),
    Interpretation: useRef(null),
    GoogleSocial: useRef(null),
  };

  const toggleAccordion = (section) => {
    setOpenSection((prevSection) => (prevSection === section ? null : section));

    if (openSection !== section) {
      setTimeout(() => {
        accordionRefs[section].current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 1000);
    }
  };

  const basis_of_contract = [
    "These Conditions apply to the Contract to the exclusion of any other terms that you seek to impose or incorporate, or which are implied by trade, custom, practice or course of dealing.",
    "The Agreement Form constitutes an offer by you to purchase the System in accordance with these Conditions. You are responsible for ensuring that the terms set out on the Agreement Form are complete and accurate.",
    "The Agreement Form shall only be deemed to be accepted when the Company signs the Agreement Form (by a handwritten or electronic signature) at which point the Contract shall come into existence.",
    "The Contract constitutes the entire agreement between the parties. You acknowledge that you have not relied on any statement, promise or representation made or given by or on behalf of the Company which is not set out in the Contract",
    "Any samples, drawings, descriptive matter, or advertising produced by the Company and any descriptions or illustrations contained in the Supplier’s catalogues or brochures are produced for the sole purpose of giving an approximate idea of the System described in them. They shall not form part of the Contract or have any contractual force.",
    "A quotation for the System given by the Supplier shall not constitute an offer. A quotation shall only be valid for a period of 20 Business Days from its date of issue and may be rescinded by the Company prior to the expiration of that period without prior notice",
  ];

  const introduction = [
    "Fusion POS reserves the right to update and change the Terms of Service by posting updates and changes to the Fusion POS website at any time. You are advised to check the Terms of Service from time to time for any updates or changes that may impact you and by continuing to use the Services you will at all times be deemed to be bound by the most current Terms of Service. By signing up for any of the services offered by Fusion POS you are agreeing to be bound by the terms and conditions set out below (Terms of Service). Any new features or tools which are added to the current Services shall also be subject to the Terms of Service.",
  ];

  const purchasing_product = [
    "It is the Client’s responsibility to read and agree to the terms and conditions prior to making payment. Once payment is received the contract between Fusion POS and the Client is in force. The Client is also entitled to sign a standing order for the weekly rent towards Fusion POS.",

    "What you pay and what you get",

    "The client pays a One-time setup fee based on the package (Standard, Premium, Enterprise) they select for the below-mentioned products. A client who signs up with Fusion POS gets the below-mentioned products.",

    "A client who signs up with Fusion POS gets below mentioned products.",

    "1 All-in-System (Touch screen)",

    "1 Caller ID",

    "1 Thermal Receipt Printer (Additional Printer each cost £250.00)",

    "1 Cash Draw at an additional charge of £75.00",
  ];

  const internet_requirement = [
    "Our hardware and software require a reliable and robust connection to the internet to take advantage of all of the online features and benefits. It is the Client’s responsibility to ensure that they have an appropriate internet connection in place prior to installation and to also ensure that the internet connection is maintained correctly. Internet speeds and consistencies can be checked by using internet speed test websites or contacting your internet service provider. Our recommended internet speeds are a minimum 7Mbps download speed and 0.6Mbps upload speed.",

    "Whilst Fusion POS employees may provide advice and an indication of the suitability of the Client’s internet connection, it remains the Client’s responsibility to ensure they have a suitable internet connection both before and after an order is placed. Fusion POS cannot be responsible for any slow responses to our system that are caused by poor internet connection.",
  ];

  const charges_rental = [
    "On acceptance of an order, a One Time SETUP FEE per terminal will be taken in addition to the rental fee. Fusion POS would charge the Client on weekly basis towards rent. Per week the rental charges would be based on the package they select ( Basic £19.99, Standard £29.99, Premium £39.99 ). After completion of the free trial period, the Client is entitled to sign a Direct Debit for the weekly rent towards Fusion POS. In addition to the weekly rental fee, customers will be assessed a service charge based on their respective locations.",
  ];

  const terms_about_cancellation = [
    "If the Client completes the stay with Fusion POS for if any trail period from the end of the ‘Free Period’ the client inherits the whole system provided by Fusion POS, thus the client is not eligible for any refunds which have been paid as a one-time Setup Charge. But before completion of any trail period excluding ‘Free Period’ if a client decides to end the relationship with Fusion POS then the client should notify Fusion POS is written about the cancellation of the services with Fusion POS, and provide 30 days of notice. Once Fusion POS receives the Whole system back from the client and if the client satisfies the conditions – (please refer return policy) then the client will be charged £150 as a cancellation fee and the same will be deducted from the setup fee charged and the balance will be paid back to the client. The refund (if any) from Fusion POS will be done as a Bank transfer. It is the sole duty of the client to provide Fusion POS with the client’s valid bank details like Account Number, Sort Code, Branch Name and Name of the Bank. In any condition, if the client provides invalid Bank details and the amount has been transferred to the given account, Fusion POS cannot be held responsible. And there wouldn’t be any further payment transactions from Fusion POS in regards to this. The Setup amount refund will not be paid as cash or Cheque. If the client breaches the FusionPOS code of conduct then Fusion POS reserves full rights to cancel all the services provided to the client with immediate effect. As per the General Data Protection Regulation (GDPR), all data associated with their account will be deleted when the account is deleted.",
  ];

  const code_of_conduct = [
    "Any client who signs with Fusion POS has to abide by the Code of Conduct. Any Client who is found not abiding by the code will face serious actions from Fusion POS. From the time the client signs up with Fusion POS till the client cancels the service, the client has to strictly abide by the following rules",
  ];

  const free_trial_period = [
    "At the time of placing an order, Fusion POS will advise the Client of the rental period, and the fee for rental for that period. From the date, the Client signs up with Fusion POS he/she will get a trial period (FREE PERIOD). After successful completion of the Free Period, the rolling contract for 12 months between the Client and Fusion POS starts wherein the Client is charged weekly rental.",
  ];

  const Payments_FPOS = [
    "Payments in regards to Onetime Setup Fee from Client towards Fusion POS can be paid as Account Deposit or Bank Transfer or Paypal.",
    "In regards to Immediate Account TOPUP",
    "Any Payments in regards to sending promotional SMS or any account billing balance top-up are done through Paypal – Online Direct Debit. To know their charges and other Terms & Conditions please visit https://www.paypal.com/uk/webapps/mpp/home as a client it’s your full responsibility to read their terms and conditions",
  ];

  const iat = [
    "Any Payments in regards to sending promotional SMS or any account billing balance top-up are done through Paypal – Online Direct Debit. To know their charges and other Terms & Conditions please visit https://www.paypal.com/uk/webapps/mpp/home as a client it’s your full responsibility to read their terms and conditions",
  ];

  const payment_gateway = [
    "We will ensure that all personal data collected, held, and processed is kept secure and protected against unauthorised or unlawful processing and against accidental loss, destruction, or damage. Further details of the technical and organisational measures which shall be taken are provided in clauses 21 to 26 of this Policy.",
  ];

  const send_sms = [
    "If a client decides to use our feature “To send promotional SMS” to promote their business, then the Client needs to have a sufficient amount of money in their accounts so that the texts to be sent successfully. If a client falls short of the required balance amount in their account to send SMS then the client can use GO-Cardless or Paypal facility to top up their balance instantly (also please refer to https://gocardless.com to know how it works). Fusion POS doesn’t provide free texts to their Client at any given point of time under any circumstances. The client will be held full responsibility for the outcome of sending a text SMS. At any given point in time Fusion POS will not refund the total sum amount required to send texts out or provide any free Texts to the Client. Any text messages which has been typed with incorrect or wrong content or with false promises or sent to inappropriate mobile numbers Client will be held with full responsibility. The maximum Characters in each SMS text should be below 160 characters. It is the duty of the Client to double-check and confirms the content and the mobile numbers before sending out texts. Please check our FAQs or check with our Service team to know in detail how the client can send SMS successfully.",
  ];

  const failure_rent = [
    "If the Client falls behind the payment schedule for 4 weeks or failed to renew the standing order or under any circumstances payment hasn’t reached Fusion POS Client’s “Boss” access would be temporarily blocked until further clearance of payments from the Client, the access wouldn’t be available. Further payment delays could seriously affect the Client’s business. If the Client is defaulter by 8 weeks Client’s System would be locked until further clearance from Fusion POS. If a Client isn’t available for business or due to any reasons if the Client’s business is shut down temporarily then it’s the sole responsibility of the Client to inform Fusion POS about the situation. Fusion POS would charge rental towards its Client for the whole stay even if a Client has active or passive business. Without clearing any previous debts or balances towards Fusion POS, Fusion POS will not initiate any process for the Change of Ownership for the business from the Fusion POS Side, if there is any change in the Ownership. The Client will be given 3 notices for the due payments from Fusion POS and if the Client hasn’t responded then the Fusion POS has full rights to take appropriate legal actions against the Client.",
  ];

  const return_policy = [
    "It is the Client’s responsibility to ensure the equipment is maintained and returned in the same condition as it was dispatched. It is the Client’s responsibility to document and provide back to FusionPOS, any defects in the cosmetic condition of the equipment within the first 24 hours of receiving the goods. Fusion POS remains the owner of the equipment at all times. It is the Client’s responsibility to arrange the safe return of equipment within 6 working days of the end of the trial period. Fusion POS may offer to collect the equipment (chargeable service) however; it is the Client’s responsibility to document the cosmetic condition before they are collected by Fusion POS (or Fusion POS third-party courier).For each calendar day after 6 working days from the end of the rental period that",

    "Fusion POS has not received the equipment we will charge an additional £15.",

    "Upon return of the equipment, Fusion POS will inspect the equipment and compile a report on the equipment. Based on the report of the engineer a refund of the deposit will be made less cancellation charge (if any). If the equipment is returned damaged beyond the value of the Onetime SETUP FEE, Fusion POS retains the right to invoice the Client for the amount added to the deposit. The Client must pay this charge within 14 days of receiving the invoice.",

    "The Client holds Fusion POS harmless against any defaults or claims arising out of the use of the equipment. The equipment rental does not include any ongoing services. The Client is responsible for informing Fusion POS of any changes to their contact details. If a client decides to end a relationship with Fusion POS after the Trial Period, then the Client has to inform Fusion POS. it is the Client’s responsibility to document the cosmetic condition before they are collected by Fusion POS (or Fusion POS’s third-party courier). If the equipment is not returned within 10 days after the end of the Trial period Fusion POS withholds all rights to charge the cancellation fee or retain the setup fee and invoice the Client. The Client is at all times responsible for ensuring timely payments to avoid default charges. Fusion POS will charge £10 for each day that payment is overdue.",
    "ii) Till Rolls",
    "The client should notify Fusion POS about the return of goods. Based on confirmation from our executive. He/She can send the parcel back to Fusion POS within 14 working days from the date of purchase. The buyer is responsible to pay for return postage.",
    "Once we have received the product(s) and if appropriate, any packaging, a refund will be credited to the payment card account within 2 weeks. Please note that it may take up to 14 days for your bank to credit your account. We are not liable, and cannot take responsibility for, any bank charges that you may incur during the refund process.",
  ];

  const risk_insurance = [
    "You shall retain all Intellectual Property Rights in any Materials that you provide to the Company to use on the Website and you hereby grant the Company a licence to such Intellectual Property Rights to the extent required to perform its obligations under this Contract.",

    "All Intellectual Property Rights arising in the System, including any domain names, shall be the property of the Company and the Company hereby grants you a non-exclusive licence to use such Intellectual Property Rights to the extent required to utilise the System in accordance with these Terms and Conditions.",

    "You agree that: the Equipment shall at all times remain the property of the Company and you shall have no right, title or interest in or to the Equipment (save the right to possession and use of the damaged and lost parts and arranging for annual Portable Appliance Testing (PAT Testing) to be carried out by an appropriately certified electrician and shall make good any damage to the Equipment.",
  ];

  const law_jurisdiction = [
    "Any disputes in regards to this Contract, and any dispute or claim arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims), shall be governed by, and construed in accordance with, English law, and the parties irrevocably submit to the exclusive jurisdiction of the courts of England and Wales.",
  ];

  const interpretation = [
    " I. Data subjects may request that we cease processing the personal data we hold about them. If a data subject makes such a request, we will retain only the amount of personal data concerning that data subject (if any) that is necessary to ensure that the personal data in question is not processed further.",
    "II. In the event that any affected personal data has been disclosed to third parties, those parties shall be informed of the applicable restrictions on processing it (unless it is impossible or would require disproportionate effort to do so).",
  ];

  return (
    <div>
      <GoToTop />
      <SeoComponent pageUrl="terms-conditions" businessTitle="FusionPOS" />
      <ScrollToTop />
      <ChatIcon />
      <Navbar />
      <div className="mt-16 text-center ">
        <h1 className="lg:text-[50px] text-[24px] text-[#333333] font-bold py-32 bg-green-100">
          Terms and Conditions
        </h1>
      </div>
      <div className="mt-5 2xl:max-w-[1250px] xl:max-w-[1140px] md:mx-12 xl:mx-auto mx-5">
        {/* Basis of contract */}
        <div id="accordion-flush" className="">
          <h2 id="accordion-flush-heading-1">
            <button
              onClick={() => toggleAccordion("BasisOfContract")}
              type="button"
              className="flex items-center justify-between w-full py-5 font-medium text-left text-gray-500 border-b border-gray-200 "
              data-accordion-target="#accordion-flush-body-2"
              aria-expanded={openSection === "BasisOfContract"}
              aria-controls="accordion-flush-body-2"
            >
              <span className="text-[#333333] ">Basis of contract</span>
              <span>
                {openSection === "BasisOfContract" ? (
                  <IoIosArrowUp size={"20px"} />
                ) : (
                  <IoIosArrowDown size={"20px"} />
                )}
              </span>
            </button>
          </h2>
          <div
            ref={accordionRefs.BasisOfContract}
            id="accordion-flush-body-2"
            className={openSection === "BasisOfContract" ? "block" : "hidden"}
            aria-labelledby="accordion-flush-heading-1"
          >
            <div className="py-5 border-b border-gray-200 dark:border-gray-700 duration-1000">
              {basis_of_contract.map((res, index) => (
                <ol className=" mb-5 text-gray-500 text-justify  ">
                  <li key={index} className="">
                    {res}
                  </li>
                </ol>
              ))}
            </div>
          </div>
        </div>

        {/* introduction      */}
        <div id="accordion-flush" className="">
          <h2 id="accordion-flush-heading-1">
            <button
              onClick={() => toggleAccordion("Introduction")}
              type="button"
              className="flex items-center justify-between w-full py-5 font-medium text-left text-gray-500 border-b border-gray-200 "
              data-accordion-target="#accordion-flush-body-1"
              aria-expanded={openSection === "Introduction"}
              aria-controls="accordion-flush-body-1"
            >
              <span className="text-[#333333] ">Introduction</span>
              <span>
                {openSection === "Introduction" ? (
                  <IoIosArrowUp size={"20px"} />
                ) : (
                  <IoIosArrowDown size={"20px"} />
                )}
              </span>
            </button>
          </h2>
          <div
            ref={accordionRefs.Introduction}
            id="accordion-flush-body-1"
            className={openSection === "Introduction" ? "block" : "hidden"}
            aria-labelledby="accordion-flush-heading-1"
          >
            <div className="py-5 border-b border-gray-200 dark:border-gray-700 duration-1000">
              {introduction.map((res, index) => (
                <p key={index} className="mb-5 text-gray-500 text-justify">
                  {res}
                </p>
              ))}
            </div>
          </div>
        </div>

        {/* Purchasing a product from Fusion POS */}
        <div id="accordion-flush" className="">
          <h2 id="accordion-flush-heading-1">
            <button
              onClick={() => toggleAccordion("PurchasingProduct")}
              type="button"
              className="flex items-center justify-between w-full py-5 font-medium text-left text-gray-500 border-b border-gray-200 "
              data-accordion-target="#accordion-flush-body-2"
              aria-expanded={openSection === "PurchasingProduct"}
              aria-controls="accordion-flush-body-2"
            >
              <span className="text-[#333333] ">
                Purchasing a product from Fusion POS
              </span>
              <span>
                {openSection === "PurchasingProduct" ? (
                  <IoIosArrowUp size={"20px"} />
                ) : (
                  <IoIosArrowDown size={"20px"} />
                )}
              </span>
            </button>
          </h2>
          <div
            ref={accordionRefs.PurchasingProduct}
            id="accordion-flush-body-2"
            className={openSection === "PurchasingProduct" ? "block" : "hidden"}
            aria-labelledby="accordion-flush-heading-1"
          >
            <div
              className="py-5 border-b border-gray-200 dark:border-gray-700
                         text-[#333333] duration-1000 "
            >
              <p className=""></p>

              {purchasing_product.map((res, index) => (
                <ol className=" mb-5 text-gray-500 text-justify">
                  <li key={index}>{res}</li>
                </ol>
              ))}
            </div>
          </div>
        </div>

        {/* Internet Requirement */}
        <div id="accordion-flush" className="">
          <h2 id="accordion-flush-heading-1">
            <button
              onClick={() => toggleAccordion("InternetRequirement")}
              type="button"
              className="flex items-center justify-between w-full py-5 font-medium text-left text-gray-500 border-b border-gray-200  dark:text-gray-400"
              data-accordion-target="#accordion-flush-body-2"
              aria-expanded={openSection === "InternetRequirement"}
              aria-controls="accordion-flush-body-2"
            >
              <span className="text-[#333333] ">Internet Requirement</span>
              <span>
                {openSection === "InternetRequirement" ? (
                  <IoIosArrowUp size={"20px"} />
                ) : (
                  <IoIosArrowDown size={"20px"} />
                )}
              </span>
            </button>
          </h2>
          <div
            ref={accordionRefs.InternetRequirement}
            id="accordion-flush-body-2"
            className={
              openSection === "InternetRequirement" ? "block" : "hidden"
            }
            aria-labelledby="accordion-flush-heading-1"
          >
            <div className="py-5 border-b border-gray-200  duration-1000">
              <div className=""></div>
              {internet_requirement.map((res, index) => (
                <ol className=" mb-5 text-gray-500 text-justify">
                  <li key={index}>{res}</li>
                </ol>
              ))}
            </div>
          </div>
        </div>

        {/* Charges Rental */}
        <div id="accordion-flush" className="">
          <h2 id="accordion-flush-heading-1">
            <button
              onClick={() => toggleAccordion("ChargesRental")}
              type="button"
              className="flex items-center justify-between w-full py-5 font-medium text-left text-gray-500 border-b border-gray-200  dark:text-gray-400"
              data-accordion-target="#accordion-flush-body-2"
              aria-expanded={openSection === "ChargesRental"}
              aria-controls="accordion-flush-body-2"
            >
              <span className="text-[#333333] ">Charges & Rental </span>
              <span>
                {openSection === "ChargesRental" ? (
                  <IoIosArrowUp size={"20px"} />
                ) : (
                  <IoIosArrowDown size={"20px"} />
                )}
              </span>
            </button>
          </h2>
          <div
            ref={accordionRefs.ChargesRental}
            id="accordion-flush-body-2"
            className={openSection === "ChargesRental" ? "block" : "hidden"}
            aria-labelledby="accordion-flush-heading-1"
          >
            <div className="py-5 border-b border-gray-200  duration-1000">
              <div className=""></div>
              {charges_rental.map((res, index) => (
                <ol className=" mb-5 text-gray-500 text-justify">
                  <li key={index}>{res}</li>
                </ol>
              ))}
            </div>
          </div>
        </div>

        {/* Terms about cancellation */}
        <div id="accordion-flush" className="">
          <h2 id="accordion-flush-heading-1">
            <button
              onClick={() => toggleAccordion("TermsAboutCancellation")}
              type="button"
              className="flex items-center justify-between w-full py-5 font-medium text-left text-gray-500 border-b border-gray-200  dark:text-gray-400"
              data-accordion-target="#accordion-flush-body-2"
              aria-expanded={openSection === "TermsAboutCancellation"}
              aria-controls="accordion-flush-body-2"
            >
              <span className="text-[#333333] ">Terms About Cancellation</span>
              <span>
                {openSection === "TermsAboutCancellation" ? (
                  <IoIosArrowUp size={"20px"} />
                ) : (
                  <IoIosArrowDown size={"20px"} />
                )}
              </span>
            </button>
          </h2>
          <div
            ref={accordionRefs.TermsAboutCancellation}
            id="accordion-flush-body-2"
            className={
              openSection === "TermsAboutCancellation" ? "block" : "hidden"
            }
            aria-labelledby="accordion-flush-heading-1"
          >
            <div className="py-5 border-b border-gray-200  duration-1000">
              <div className=""></div>
              {terms_about_cancellation.map((res, index) => (
                <ol className=" mb-5 text-gray-500 text-justify">
                  <li key={index}>{res}</li>
                </ol>
              ))}
            </div>
          </div>
        </div>

        {/* Code of Conduct  */}
        <div id="accordion-flush" className="">
          <h2 id="accordion-flush-heading-1">
            <button
              onClick={() => toggleAccordion("CodeofConduct")}
              type="button"
              className="flex items-center justify-between w-full py-5 font-medium text-left text-gray-500 border-b border-gray-200  dark:text-gray-400"
              data-accordion-target="#accordion-flush-body-2"
              aria-expanded={openSection === "CodeofConduct"}
              aria-controls="accordion-flush-body-2"
            >
              <span className="text-[#333333] ">Code of Conduct</span>
              <span>
                {openSection === "CodeofConduct" ? (
                  <IoIosArrowUp size={"20px"} />
                ) : (
                  <IoIosArrowDown size={"20px"} />
                )}
              </span>
            </button>
          </h2>
          <div
            ref={accordionRefs.CodeofConduct}
            id="accordion-flush-body-2"
            className={openSection === "CodeofConduct" ? "block" : "hidden"}
            aria-labelledby="accordion-flush-heading-1"
          >
            <div className="py-5 border-b border-gray-200  duration-1000">
              <div className=""> </div>
              {code_of_conduct.map((res, index) => (
                <ol className=" mb-5 text-gray-500 text-justify">
                  <li key={index}>{res}</li>
                </ol>
              ))}
            </div>
          </div>
        </div>

        {/* Free &  Trial Period */}
        <div id="accordion-flush" className="">
          <h2 id="accordion-flush-heading-1">
            <button
              onClick={() => toggleAccordion("FreeTrialPeriod")}
              type="button"
              className="flex items-center justify-between w-full py-5 font-medium text-left text-gray-500 border-b border-gray-200  dark:text-gray-400"
              data-accordion-target="#accordion-flush-body-2"
              aria-expanded={openSection === "FreeTrialPeriod"}
              aria-controls="accordion-flush-body-2"
            >
              <span className="text-[#333333] ">Free & Trial Period</span>
              <span>
                {openSection === "FreeTrialPeriod" ? (
                  <IoIosArrowUp size={"20px"} />
                ) : (
                  <IoIosArrowDown size={"20px"} />
                )}
              </span>
            </button>
          </h2>
          <div
            ref={accordionRefs.FreeTrialPeriod}
            id="accordion-flush-body-2"
            className={openSection === "FreeTrialPeriod" ? "block" : "hidden"}
            aria-labelledby="accordion-flush-heading-1"
          >
            <div className="py-5 border-b border-gray-200  duration-1000">
              <div className=""> </div>
              {free_trial_period.map((res, index) => (
                <ol className=" mb-5 text-gray-500 text-justify">
                  <li key={index}>{res}</li>
                </ol>
              ))}
            </div>
          </div>
        </div>

        {/* Payments towards Fusion POS */}
        <div id="accordion-flush" className="">
          <h2 id="accordion-flush-heading-1">
            <button
              onClick={() => toggleAccordion("PaymentsFPOS")}
              type="button"
              className="flex items-center justify-between w-full py-5 font-medium text-left text-gray-500 border-b border-gray-200  dark:text-gray-400"
              data-accordion-target="#accordion-flush-body-2"
              aria-expanded={openSection === "PaymentsFPOS"}
              aria-controls="accordion-flush-body-2"
            >
              <span className="text-[#333333] ">
                Payments towards Fusion POS
              </span>
              <span>
                {openSection === "PaymentsFPOS" ? (
                  <IoIosArrowUp size={"20px"} />
                ) : (
                  <IoIosArrowDown size={"20px"} />
                )}
              </span>
            </button>
          </h2>
          <div
            ref={accordionRefs.PaymentsFPOS}
            id="accordion-flush-body-2"
            className={openSection === "PaymentsFPOS" ? "block" : "hidden"}
            aria-labelledby="accordion-flush-heading-1"
          >
            <div className="py-1 border-b border-gray-200  duration-1000">
              <div className="font-bold py-3 text-[#333333]"></div>
              {Payments_FPOS.map((res, index) => (
                <ol className=" mb-5 text-gray-500 text-justify">
                  <li key={index}>{res}</li>
                </ol>
              ))}
            </div>
          </div>
        </div>

        {/* In regards to Immediate Account TOPUP */}
        <div id="accordion-flush" className="">
          <h2 id="accordion-flush-heading-1">
            <button
              onClick={() => toggleAccordion("IAT")}
              type="button"
              className="flex items-center justify-between w-full py-5 font-medium text-left text-gray-500 border-b border-gray-200  dark:text-gray-400"
              data-accordion-target="#accordion-flush-body-2"
              aria-expanded={openSection === "IAT"}
              aria-controls="accordion-flush-body-2"
            >
              <span className="text-[#333333] ">
                In regards to Immediate Account TOPUP
              </span>
              <span>
                {openSection === "IAT" ? (
                  <IoIosArrowUp size={"20px"} />
                ) : (
                  <IoIosArrowDown size={"20px"} />
                )}
              </span>
            </button>
          </h2>
          <div
            ref={accordionRefs.IAT}
            id="accordion-flush-body-2"
            className={openSection === "IAT" ? "block" : "hidden"}
            aria-labelledby="accordion-flush-heading-1"
          >
            <div className="py-5 border-b border-gray-200  duration-1000">
              <div className="font-bold py-3 text-[#333333]"></div>
              {iat.map((res, index) => (
                <ol className=" mb-5 text-gray-500 text-justify">
                  <li key={index}>{res}</li>
                </ol>
              ))}
            </div>
          </div>
        </div>

        {/* Payment Gateway */}
        <div id="accordion-flush" className="">
          <h2 id="accordion-flush-heading-1">
            <button
              onClick={() => toggleAccordion("PaymentGateway")}
              type="button"
              className="flex items-center justify-between w-full py-5 font-medium text-left text-gray-500 border-b border-gray-200  dark:text-gray-400"
              data-accordion-target="#accordion-flush-body-2"
              aria-expanded={openSection === "PaymentGateway"}
              aria-controls="accordion-flush-body-2"
            >
              <span className="text-[#333333] ">Payment Gateway</span>
              <span>
                {openSection === "PaymentGateway" ? (
                  <IoIosArrowUp size={"20px"} />
                ) : (
                  <IoIosArrowDown size={"20px"} />
                )}
              </span>
            </button>
          </h2>
          <div
            ref={accordionRefs.PaymentGateway}
            id="accordion-flush-body-2"
            className={openSection === "PaymentGateway" ? "block" : "hidden"}
            aria-labelledby="accordion-flush-heading-1"
          >
            <div className="py-5 border-b border-gray-200  duration-1000">
              <div className="font-bold py-3 text-[#333333]"></div>
              {payment_gateway.map((res, index) => (
                <ol className=" mb-5 text-gray-500 text-justify">
                  <li key={index}>{res}</li>
                </ol>
              ))}
            </div>
          </div>
        </div>

        {/*Sending Promotional SMS  */}
        <div id="accordion-flush" className="">
          <h2 id="accordion-flush-heading-1">
            <button
              onClick={() => toggleAccordion("SendSMS")}
              type="button"
              className="flex items-center justify-between w-full py-5 font-medium text-left text-gray-500 border-b border-gray-200  dark:text-gray-400"
              data-accordion-target="#accordion-flush-body-2"
              aria-expanded={openSection === "SendSMS"}
              aria-controls="accordion-flush-body-2"
            >
              <span className="text-[#333333] ">Sending Promotional SMS</span>
              <span>
                {openSection === "SendSMS" ? (
                  <IoIosArrowUp size={"20px"} />
                ) : (
                  <IoIosArrowDown size={"20px"} />
                )}
              </span>
            </button>
          </h2>
          <div
            ref={accordionRefs.SendSMS}
            id="accordion-flush-body-2"
            className={openSection === "SendSMS" ? "block" : "hidden"}
            aria-labelledby="accordion-flush-heading-1"
          >
            <div className="py-5 border-b border-gray-200  duration-1000">
              <div className="font-bold py-3 text-[#333333]"></div>
              {send_sms.map((res, index) => (
                <ol className=" mb-5 text-gray-500 text-justify">
                  <li key={index}>{res}</li>
                </ol>
              ))}
            </div>
          </div>
        </div>

        {/*Failure of weekly rent & Consequences  */}
        <div id="accordion-flush" className="">
          <h2 id="accordion-flush-heading-1">
            <button
              onClick={() => toggleAccordion("FailureRent")}
              type="button"
              className="flex items-center justify-between w-full py-5 font-medium text-left text-gray-500 border-b border-gray-200  dark:text-gray-400"
              data-accordion-target="#accordion-flush-body-2"
              aria-expanded={openSection === "FailureRent"}
              aria-controls="accordion-flush-body-2"
            >
              <span className="text-[#333333] ">
                Failure of weekly rent & Consequences
              </span>
              <span>
                {openSection === "FailureRent" ? (
                  <IoIosArrowUp size={"20px"} />
                ) : (
                  <IoIosArrowDown size={"20px"} />
                )}
              </span>
            </button>
          </h2>
          <div
            ref={accordionRefs.FailureRent}
            id="accordion-flush-body-2"
            className={openSection === "FailureRent" ? "block" : "hidden"}
            aria-labelledby="accordion-flush-heading-1"
          >
            <div className="py-5 border-b border-gray-200  duration-1000">
              <div className="font-bold py-3 text-[#333333]"></div>
              {failure_rent.map((res, index) => (
                <ol className=" mb-5 text-gray-500 text-justify">
                  <li key={index}>{res}</li>
                </ol>
              ))}
            </div>
          </div>
        </div>

        {/* Return Policy  */}
        <div id="accordion-flush" className="">
          <h2 id="accordion-flush-heading-1">
            <button
              onClick={() => toggleAccordion("ReturnPolicy")}
              type="button"
              className="flex items-center justify-between w-full py-5 font-medium text-left text-gray-500 border-b border-gray-200  dark:text-gray-400"
              data-accordion-target="#accordion-flush-body-2"
              aria-expanded={openSection === "ReturnPolicy"}
              aria-controls="accordion-flush-body-2"
            >
              <span className="text-[#333333] ">Return Policy</span>
              <span>
                {openSection === "ReturnPolicy" ? (
                  <IoIosArrowUp size={"20px"} />
                ) : (
                  <IoIosArrowDown size={"20px"} />
                )}
              </span>
            </button>
          </h2>
          <div
            ref={accordionRefs.ReturnPolicy}
            id="accordion-flush-body-2"
            className={openSection === "ReturnPolicy" ? "block" : "hidden"}
            aria-labelledby="accordion-flush-heading-1"
          >
            <div className="py-5 border-b border-gray-200  duration-1000">
              <div className="font-bold py-3 text-[#333333]"></div>
              {return_policy.map((res, index) => (
                <ol className=" mb-5 text-gray-500 text-justify">
                  <li key={index}>{res}</li>
                </ol>
              ))}
            </div>
          </div>
        </div>

        {/* Risk and insurance */}
        <div id="accordion-flush" className="">
          <h2 id="accordion-flush-heading-1">
            <button
              onClick={() => toggleAccordion("RiskInsurance")}
              type="button"
              className="flex items-center justify-between w-full py-5 font-medium text-left text-gray-500 border-b border-gray-200  dark:text-gray-400"
              data-accordion-target="#accordion-flush-body-2"
              aria-expanded={openSection === "RiskInsurance"}
              aria-controls="accordion-flush-body-2"
            >
              <span className="text-[#333333] ">Risk and insurance</span>
              <span>
                {openSection === "RiskInsurance" ? (
                  <IoIosArrowUp size={"20px"} />
                ) : (
                  <IoIosArrowDown size={"20px"} />
                )}
              </span>
            </button>
          </h2>
          <div
            ref={accordionRefs.RiskInsurance}
            id="accordion-flush-body-2"
            className={openSection === "RiskInsurance" ? "block" : "hidden"}
            aria-labelledby="accordion-flush-heading-1"
          >
            <div className="py-5 border-b border-gray-200  duration-1000">
              <div className="font-bold py-3 text-[#333333]"></div>
              {risk_insurance.map((res, index) => (
                <ol className=" mb-5 text-gray-500 text-justify">
                  <li key={index}>{res}</li>
                </ol>
              ))}
            </div>
          </div>
        </div>

        {/* Law and jurisdiction */}
        <div id="accordion-flush" className="">
          <h2 id="accordion-flush-heading-1">
            <button
              onClick={() => toggleAccordion("LawJurisdiction")}
              type="button"
              className="flex items-center justify-between w-full py-5 font-medium text-left text-gray-500 border-b border-gray-200  dark:text-gray-400"
              data-accordion-target="#accordion-flush-body-2"
              aria-expanded={openSection === "LawJurisdiction"}
              aria-controls="accordion-flush-body-2"
            >
              <span className="text-[#333333] ">Law and jurisdiction</span>
              <span>
                {openSection === "LawJurisdiction" ? (
                  <IoIosArrowUp size={"20px"} />
                ) : (
                  <IoIosArrowDown size={"20px"} />
                )}
              </span>
            </button>
          </h2>
          <div
            ref={accordionRefs.LawJurisdiction}
            id="accordion-flush-body-2"
            className={openSection === "LawJurisdiction" ? "block" : "hidden"}
            aria-labelledby="accordion-flush-heading-1"
          >
            <div className="py-5 border-b border-gray-200  duration-1000">
              <div className="font-bold py-3 text-[#333333]"></div>
              {law_jurisdiction.map((res, index) => (
                <ol className=" mb-5 text-gray-500 text-justify">
                  <li key={index}>{res}</li>
                </ol>
              ))}
            </div>
          </div>
        </div>

        {/* Interpretation  */}
        <div id="accordion-flush" className="">
          <h2 id="accordion-flush-heading-1">
            <button
              onClick={() => toggleAccordion("Interpretation")}
              type="button"
              className="flex items-center justify-between w-full py-5 font-medium text-left text-gray-500 border-b border-gray-200  dark:text-gray-400"
              data-accordion-target="#accordion-flush-body-2"
              aria-expanded={openSection === "Interpretation"}
              aria-controls="accordion-flush-body-2"
            >
              <span className="text-[#333333] ">
                Restriction of Personal Data Processing
              </span>
              <span>
                {openSection === "Interpretation" ? (
                  <IoIosArrowUp size={"20px"} />
                ) : (
                  <IoIosArrowDown size={"20px"} />
                )}
              </span>
            </button>
          </h2>
          <div
            ref={accordionRefs.Interpretation}
            id="accordion-flush-body-2"
            className={openSection === "Interpretation" ? "block" : "hidden"}
            aria-labelledby="accordion-flush-heading-1"
          >
            <div className="py-5 border-b border-gray-200  duration-1000">
              <div className="font-bold py-3 text-[#333333]"></div>
              {interpretation.map((res, index) => (
                <ol className=" mb-5 text-gray-500 text-justify">
                  <li key={index}>{res}</li>
                </ol>
              ))}
            </div>
          </div>
        </div>

        {/*Google & Social media.  */}
        <div id="accordion-flush" className="">
          <h2 id="accordion-flush-heading-1">
            <button
              onClick={() => toggleAccordion("GoogleSocial")}
              type="button"
              className="flex items-center justify-between w-full py-5 font-medium text-left text-gray-500 border-b border-gray-200  dark:text-gray-400"
              data-accordion-target="#accordion-flush-body-2"
              aria-expanded={openSection === "GoogleSocial"}
              aria-controls="accordion-flush-body-2"
            >
              <span className="text-[#333333] ">Google & Social media.</span>
              <span>
                {openSection === "GoogleSocial" ? (
                  <IoIosArrowUp size={"20px"} />
                ) : (
                  <IoIosArrowDown size={"20px"} />
                )}
              </span>
            </button>
          </h2>
          <div
            ref={accordionRefs.GoogleSocial}
            id="accordion-flush-body-2"
            className={openSection === "GoogleSocial" ? "block" : "hidden"}
            aria-labelledby="accordion-flush-heading-1"
          >
            <div className="py-5 border-b border-gray-200  duration-1000 text-[#333333]">
              <div className=" py-3 ">
                <p className="font-bold text-base ">Google My Business</p>
                <p className="pt-4 text-[#333333] text-base">
                  As part of our contract commitment and to optimize your online
                  presence, you agreed to give access to your Google My Business
                  account for Fusion POS. Granting access will enable us to
                  effectively manage and enhance your GMB listing based on our
                  company's terms and policies.
                </p>
              </div>
              <div>
                <p className="text-[#333333] my-3">
                  As a trusted partner, we would utilize GMB access to implement
                  the following key activities:
                </p>
                <ol className=" mb-5 text-gray-500 text-justify list-disc">
                  <li className="my-3 ml-5">
                    <span className="text-[#333333] font-base font-bold ">
                      Listing Optimization:{" "}
                    </span>{" "}
                    We will review and enhance your GMB listing to ensure it is
                    fully optimized, including accurate business information,
                    category selection, and compelling descriptions.
                  </li>
                  <li className="my-3 ml-5">
                    <span className="text-[#333333] font-base font-bold ">
                      Regular Updates:{" "}
                    </span>{" "}
                    We will monitor and update your GMB profile with the latest
                    business hours, contact details, website links, and other
                    essential information, keeping it synchronized with your
                    business operations.
                  </li>
                  <li className="my-3 ml-5">
                    <span className="text-[#333333] font-base font-bold ">
                      Customer Engagement:{" "}
                    </span>{" "}
                    We will actively respond to customer reviews, address
                    queries, and engage with your audience to enhance customer
                    satisfaction and maintain a positive online reputation.
                  </li>
                  <li className="my-3 ml-5">
                    <span className="text-[#333333] font-base font-bold ">
                      Performance Insights:{" "}
                    </span>{" "}
                    We will analyze GMB performance data, providing valuable
                    insights into customer behavior, search trends, and user
                    interactions. This information will assist us in making
                    data-driven decisions to enhance your online visibility and
                    reach.
                  </li>
                </ol>
              </div>
              <div>
                <p className="text-[#333333] my-3">
                  Granting us access to your GMB account will enable us to
                  streamline these important activities, ensuring that your
                  business remains visible and engaging to potential customers
                  searching for your products or services. Rest assured that we
                  treat your business information with the utmost
                  confidentiality and comply with all security and privacy
                  standards.
                </p>
                <p className="text-[#333333] my-3 font-bold">
                  Facebook, Instagram & other social media platforms
                </p>
                <p className="text-[#333333] my-3">
                  As part of our contract commitment and to optimize your online
                  presence, you agreed to give access to your Facebook,
                  Instagram, and Twitter accounts to enhance your online
                  presence. Our activities will include content management,
                  community engagement, and performance tracking in line with
                  our company's terms and policies.
                </p>
                <p className="text-[#333333] my-3">
                  Rest assured, we prioritize the privacy and security of your
                  accounts. By granting us access, you agree to our terms and
                  policies. We will act in your best interest, maintain
                  confidentiality, and promptly report any changes made to your
                  accounts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
