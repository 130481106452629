import React, { useState } from 'react';

const UnsubscribePage = () => {
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);

  return (
    <div className="flex justify-center items-center min-h-screen bg-blue-50 p-4 md:p-8 lg:p-12">
      <div className="w-full max-w-xs sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl mx-auto">
        <img 
          src="https://fusionbucket.co.uk/fmb/fposlogo.png" 
          alt="Fusion My Business" 
          className="mx-auto mb-6 sm:mb-8 md:mb-10 lg:mb-12"
        />
        <div className="bg-white p-6 sm:p-8 md:p-10 lg:p-12 rounded-xl shadow-lg">
          {!isUnsubscribed ? (
            <>
              <h1 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4">Unsubscribe</h1>
              <p className="mb-4 sm:mb-6 md:mb-8">
                Are you sure you want to discontinue receiving emails from Fusion?
              </p>
              <button 
                onClick={() => setIsUnsubscribed(true)}
                className="bg-[#173303] text-white px-4 py-2 md:px-6 md:py-3 rounded-lg shadow-md transition"
              >
                Unsubscribe from all email
              </button>
            </>
          ) : (
            <>
              <h1 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4">You have been unsubscribed and will no longer receive our communications.</h1>
              <p className="mb-4 sm:mb-6 md:mb-8">
                If this was a mistake, you can easily
                <button 
                  onClick={() => setIsUnsubscribed(false)} 
                  className="underline text-black transition ml-1"
                >
                  re-subscribe.
                </button>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default UnsubscribePage;
