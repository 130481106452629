import React from "react";
import Navbar from "../components/common/navbar/Navbar";
import Footer from "../components/common/footer/Footer";
import PrimeComponent from "../components/about/PrimeComponent/PrimeComponent";
import Vision from "../components/about/Vision/Vision";
import GoToTop from "../components/common/scroll-icon/GoToTop";
import ScrollToTop from "../components/common/scroll-icon/ScrollToTop";
import ChatIcon from "../components/common/chat/ChatIcon";
import FusionTimeLine from "../components/about/fusion_timeline/FusionTimeLine";
import ClientLogo from "../components/about/client-logo/ClientLogo";
import SeoComponent from "../SeoComponent";

const About = () => {
  return (
    <>
      <SeoComponent pageUrl="about-us" businessTitle="FusionPOS" />
      <Navbar />
      <PrimeComponent />
      <Vision />
      <FusionTimeLine />
      <ClientLogo />
      <GoToTop />
      <ScrollToTop />
      <ChatIcon />
      <Footer />
    </>
  );
};

export default About;
