import React from "react";
import HeaderContainer from "../../common/header-container/HeaderContainer";
import "./styles.css";
import { Link } from "react-router-dom";

const NoChargePanal = () => {
  return (
    <>
      <HeaderContainer>
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="800"
          className="mt-10 mb-10 no-charge-panal border rounded-[15px]  mx-5
                    md:mt-32 md:mb-10  md:mx-14   lg:mx-14 2xl:mx-2 xl:mb-5"
        >
          <div className="font-work-sans ">
            <p
              className="text-[16px] text-white font-bold mx-6 p-3 text-center
                           md:text-[30px]  md:w-3/4 md:p-5 md:mx-auto capitalize
                          xl:text-[40px] lg:text-center lg:w-3/4 lg:p-5 "
            >
              Switch to FusionPOS and grow your business, There's No Charge To
              Start Selling With Fusion.
            </p>
            <p
              className="text-[16px]  text-center mb-3
                         lg:text-[24px] font-medium capitalize
                         2xl:text-[24px] 2xl:leading-[40px] text-white"
            >
              Start With Zero Costs. No subscription fees.
            </p>
            <div className="flex justify-center pb-5 md:pb-6 lg:pb-12">
              <Link to="/contactus">
                <button
                  className="
                p-1 px-[25px] bg-[#78d73d] text-[#173303] text-[18px] shadow-lg duration-1000
                font-bold  rounded-[10px] hover:text-[#78d73d] hover:bg-[#173303] 
                md:text-[20px] md:mt-4 md:p-3
                lg:text-[20px] lg:mt-8 lg:p-3
                "
                >
                  Request Demo
                </button>
              </Link>
            </div>
          </div>
        </div>
      </HeaderContainer>
      <div className="bg-green-50">
        <HeaderContainer>
          <div className="pt-1 pb-10 mx-0 md:mx-10 lg:mx-11 2xl:mx-0">
            <div
              className="flex flex-col mt-2 
                         md:mt-6 md:flex-row md:gap-20 lg:gap-5  "
            >
              {/*Left content  */}
              <div
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="800"
                className=" m-3 font-work-sans       
                       md:w-1/2 md:m-4 lg:w-3/4"
              >
                <p
                  className="text-[#333333] text-[24px] leading-[34px]  font-bold   mx-5
                               md:text-[36px] md:leading-[44px] md:mx-0 capitalize
                               lg:text-[40px] lg:leading-[57px] lg:w-[80%] xl:w-[80%] "
                >
                  Our <span className="text-[#9fe870]"> Mission </span>
                  is to empower every restaurant to
                  <span className="text-[#9fe870]"> take control </span>
                  of their future.
                </p>
              </div>

              {/*Right content */}
              <div
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="800"
                className="         
                       md:w-1/2 font-dm-sans
                       flex justify-center items-center
                       md:flex md:justify-end md:items-center 
                       lg:justify-start lg:items-center "
              >
                <div className="flex flex-col">
                  <p
                    className="text-[#333333]   
                                hidden md:block font-semibold
                                md:text-[20px] md:leading-[24px]
                                2xl:text-[20px] 2xl:leading-[29px] "
                  >
                    Fusion has been powering the restaurants performances with
                    their top customers since 2017.
                  </p>
                  <p
                    className="text-[#333333]   hidden md:block font-semibold
                                md:text-[20px] md:leading-[24px]
                                2xl:text-[20px] 2xl:leading-[29px] mt-3"
                  >
                    With our multi-channel platform, restaurants provide
                    first-class experiences and interactions to their customers.
                  </p>
                  <div className="    ">
                    <Link to="/about-us">
                      <button
                        className="
                             border border-[#173303] rounded-[10px] text-[18px] shadow-lg
                              text-[#173303] font-bold py-[4px] px-[16px] duration-1000
                              hover:text-[#9fe870] hover:bg-[#173303]
                             md:mt-4
                             2xl:py-[12px] 2xl:px-[16px] 2xl:font-semibold
                            "
                      >
                        Learn More
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </HeaderContainer>
      </div>
    </>
  );
};

export default NoChargePanal;
