import React from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import FAQ from "./FAQ";
import GoToTop from "../scroll-icon/GoToTop";
import ScrollToTop from "../scroll-icon/ScrollToTop";
import ChatIcon from "../chat/ChatIcon";
import MissionAndPromises from "./MissionAndPromises";

const MainFaq = () => {
  return (
    <div>
      <GoToTop />
      <ScrollToTop />
      <Navbar />
      <MissionAndPromises />
      <FAQ />
      <ChatIcon />
      <Footer />
    </div>
  );
};

export default MainFaq;
