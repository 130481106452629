import React from "react";
import HeaderContainer from "../../../common/header-container/HeaderContainer";
import imgtakecontrol from "./img/fpos_caller_id.png";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SupportPrime = () => {
  const TakeControl = {
    headline: "Caller ID Setup",
    title1: "A step by step guide on how to install FusionPOS Caller ID.",
    description1: `Follow this helpful guide to setup the Fusion POS Caller ID system, there are two options available for both ADSL and Cable internet. Follow this guide to have your FusionPOS system fully operational in a matter of minutes.`,
    img: imgtakecontrol,
  };

  return (
    <div>
      <HeaderContainer>
        <div
          className="mt-16 mb-5 mx-[16px] 
                        md:mt-10 md:mb-20 md:mx-14
                        lg:mx-16 lg:mt-32 xl:mt-36
                        2xl:mx-0 2xl:mt-40
                        "
        >
          <div className="flex flex-col lg:flex-row gap-0 lg:gap-10 ">
            {/*Right content  */}
            {
              <div
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="800"
                className="w-full lg:w-1/2
                          md:mt-6
                          lg:mt-0
                          
                          2xl:ml-auto  "
              >
                {/* headline  */}
                <div className="">
                  <p
                    className="text-[36px] leading-[44px] font-bold text-[#333333]
                                font-work-sans hidden xl:block capitalize"
                  >
                    {TakeControl.headline}
                  </p>
                </div>

                {/* title1  */}
                <div className="mt-5 2xl:mt-5">
                  <p className="text-[24px] leading-[30px]  font-bold text-[#333333] font-work-sans">
                    {TakeControl.title1}
                  </p>
                  <p
                    className="text-[#333333] text-[18px] leading-[24px] mt-5 mb-6 font-dm-sans                       
                           md:text-[20px] md:leading-[28px] font-medium text-justify                        
                           lg:text-[20px] lg:leading-[40px] lg:text-start
                           xl:text-[20px] xl:leading-[40px] 
                           2xl:text-[20px] 2xl:leading-[40px] 2xl:my-5 2xl:w-[90%] "
                  >
                    {TakeControl.description1}
                  </p>
                </div>

                <div className="mt-5 flex justify-center md:justify-start">
                  <Link to="/contactus">
                    <button
                      className="
                   p-2.5 px-[15px] bg-[#9fe870] text-[#173303] text-[18px]
                   font-semibold  rounded-[10px] font-dm-sans leading-[30px] duration-1000
                   hover:text-[#9fe870] hover:bg-[#173303] shadow-lg
                   "
                    >
                      Book a Free Demo
                    </button>
                  </Link>
                </div>
              </div>
            }

            {/*Left image  */}
            {
              <div
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="800"
                className="w-full lg:w-1/2 mt-5 md:mt-10"
              >
                <p
                  className="text-[24px] leading-[36px] font-bold text-[#333333]
                               font-work-sans  block text-center mb-10 
                               md:text-[24px] md:leading-[40px]  capitalize
                               lg:text-[40px] lg:leading-[48px]
                               md:text-start 
                               xl:hidden
                               "
                >
                  {TakeControl.headline}
                </p>
                <LazyLoadImage
                  src={TakeControl.img}
                  alt="poster"
                  className="w-[100%] lg:mx-auto overflow-auto md:overflow-hidden"
                />
              </div>
            }
          </div>
        </div>
      </HeaderContainer>
    </div>
  );
};

export default SupportPrime;
