import React, { useState } from "react";
import HeaderContainer from "../../common/header-container/HeaderContainer";
import MonthlyPlan from "./monthly-plan/MonthlyPlan";
import { Link } from "react-router-dom";
import "./styles.css";

const PricePlan = () => {
  const [subscriptionType, setSubscriptionType] = useState("monthly");
  return (
    <div>
      <HeaderContainer>
        <div
          className="mt-[30px] mb-5 mx-[16px] 
                        md:mt-20 md:mb-20 md:mx-16
                        lg:mx-16 2xl:mx-0"
        >
          <div>
            <div
              className="w-fit mx-auto bg-[#9FE870] rounded-full p-2 flex gap-2 my-5 font-work-sans font-medium "
              style={{ boxShadow: "inset 9px 9px 15px 1px rgba(0,0,0,0.2)" }}
            >
              <button
                onClick={() => setSubscriptionType("monthly")}
                className={`
                            ${
                              subscriptionType === "monthly"
                                ? "bg-white rounded-full shadow-lg"
                                : "bg-none"
                            } 
                            px-8 md:px-10 py-2 md:py-2.5 text-[#1A1A1A] transition-all duration-200 hover:shadow-lg
                            hover:bg-white hover:rounded-full min-w-fit`}
              >
                Weekly
              </button>
              <button
                onClick={() => setSubscriptionType("yearly")}
                className={`
                            ${
                              subscriptionType === "yearly"
                                ? "bg-white rounded-full shadow-lg"
                                : "bg-none"
                            } 
                            px-8 md:px-10 py-2 md:py-2.5 text-[#1A1A1A] transition-all duration-200 
                            hover:shadow-lg hover:bg-white hover:rounded-full min-w-fit`}
              >
                Yearly (save 18%)
              </button>
            </div>
          </div>
          <MonthlyPlan subscriptionType={subscriptionType} />
        </div>
      </HeaderContainer>
      <div className="  my-20 ">
        <HeaderContainer>
          <div
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="800"
            className="mt-10 mb-10  mx-5 no-charge-panal rounded-[20px]
                    md:mt-32 md:mb-10  md:mx-14   lg:mx-14 2xl:mx-2"
          >
            <div className="font-work-sans text-center">
              <h4
                className="
                          text-[32px] text-white mx-6 text-center mt-6
                          md:text-[30px] md:mx-auto capitalize
                          xl:text-[50px] lg:text-center 
                          font-extrabold  
                          bg-gradient-to-r from-yellow-400 via-green-500 
                          to-blue-300  inline-block text-transparent bg-clip-text "
              >
                Enterprises
              </h4>

              <h4
                className="text-[20px] text-white font-bold mx-6 p-3 text-center
                           md:text-[30px]  md:w-3/4 md:p-5 md:mx-auto capitalize
                          xl:text-[35px] lg:text-center lg:w-3/4 lg:p-5 "
              >
                Design A Perfect Plan That Fits The Unique Needs Of Your High
                Volume Business
              </h4>
              <p
                className="text-[14px]  text-center mb-3 w-[90%] mx-auto
                         lg:text-[24px] font-medium capitalize
                         2xl:text-[24px] 2xl:leading-[40px] text-white"
              >
                Reach out to us for personalized quotes tailored just for you.
              </p>
              <p
                className="text-[14px]  text-center mb-3 w-[90%] mx-auto
                         lg:text-[24px] font-medium capitalize
                         2xl:text-[24px] 2xl:leading-[40px] text-white"
              >
                Enjoy plans made specifically for your convenience.
              </p>
              <div className="flex justify-center pb-5 md:pb-6 lg:pb-12">
                <Link to="/contactus">
                  <button
                    className="
                p-1 px-[25px] bg-[#9fe870] text-[#173303] text-[18px] shadow-lg duration-1000
                font-bold  rounded-[10px] hover:text-[#9fe870] hover:bg-[#173303] 
                md:text-[20px] md:mt-4 md:p-3
                lg:text-[20px] lg:mt-8 lg:p-3
                "
                  >
                    Get In Touch
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </HeaderContainer>
      </div>
    </div>
  );
};

export default PricePlan;
