import React from "react";
import setup_image from "./img/caller-id.jpg";
import need_help from "./img/need_help.png";
import HeaderContainer from "../../header-container/HeaderContainer";
import { Link } from "react-router-dom";

const CallerIdSetup = () => {
  return (
    <div>
      <HeaderContainer>
        <img src={setup_image} alt={setup_image} className="mx-5 md:mx-10" />
        <div className="flex justify-center items-center flex-col my-32 font-work-sans mx-5">
          <h3 className="text-center text-[30px] py-5 font-bold">Need help</h3>
          <img src={need_help} alt={need_help} className="rounded-full py-5" />
          <div className="">
            <p className="block text-center">
              27 Old Gloucester Street, London, WC1N 3AX, UK.
            </p>
            <div className="flex flex-col justify-center items-center ">
              <p>
                Telephone :{" "}
                <Link to="(+44) 330 122 5960" className="text-green-500">
                  (+44) 330 122 5960
                </Link>
              </p>
              <p className="block">
                Email :{" "}
                <Link to="/support@fusionpos.co.uk" className="text-green-500">
                  support@fusionpos.co.uk
                </Link>
              </p>
            </div>
          </div>
        </div>
      </HeaderContainer>
    </div>
  );
};

export default CallerIdSetup;
