import React from "react";
import HeaderContainer from "../../common/header-container/HeaderContainer";

const FusionTimeLine = () => {
  return (
    <div className="flex justify-center bg-green-100 my-4">
      <HeaderContainer>
        <div className="lg:mt-16 mt-5 mb-5 lg:mb-10 ml-[320px] md:block hidden">
          <ol class="relative border-s-4 border-gray-200 dark:border-green-700 h-[200px]">
            <li class="mb-10 ms-6  w-[300px]">
              <span class="absolute flex items-center justify-center w-20 h-20 rounded-full -start-10 ring-8 ring-white dark:ring-green-700 dark:bg-green-200">
                <svg
                  class="w-5 h-5 text-green-800"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                </svg>
              </span>
              <time class="block mb-2 text-[32px] font-normal leading-none ml-12">
                May, 2017
              </time>
              <p class="mb-4 text-[32px] text-green-500 ml-12 font-bold">
                FusionPOS
              </p>
            </li>
          </ol>
          <ol class="relative border-s-4 border-gray-200 dark:border-green-700 h-[200px]">
            <li class="mb-10 ms-6  w-[300px]">
              <span class="absolute flex items-center justify-center w-20 h-20 rounded-full -start-10 ring-8 ring-white dark:ring-green-700 dark:bg-green-200">
                <svg
                  class="w-5 h-5 text-green-800"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                </svg>
              </span>
              <time class="block mb-2 text-[32px] font-semibold leading-none ml-12 absolute top-2 right-[420px] text-nowrap">
                October, 2017
              </time>
              <p class="mb-4 text-[32px] text-green-500 absolute top-10 right-[405px] text-nowrap font-bold">
                Fusion Suite
              </p>
            </li>
          </ol>
          <ol class="relative border-s-4 border-gray-200 dark:border-green-700 h-[200px]">
            <li class="mb-10 ms-6  w-[300px]">
              <span class="absolute flex items-center justify-center w-20 h-20 rounded-full -start-10 ring-8 ring-white dark:ring-green-700 dark:bg-green-200">
                <svg
                  class="w-5 h-5 text-green-800"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                </svg>
              </span>
              <time class="block mb-2 text-[32px] font-normal leading-none ml-12">
                February, 2019
              </time>
              <p class="mb-4 text-[32px] text-green-500 ml-12 font-bold">
                Fusion Drive
              </p>
            </li>
          </ol>
          <ol class="relative border-s-4 border-gray-200 dark:border-green-700 h-[200px]">
            <li class="mb-10 ms-6  w-[300px]">
              <span class="absolute flex items-center justify-center w-20 h-20 rounded-full -start-10 ring-8 ring-white dark:ring-green-700 dark:bg-green-200">
                <svg
                  class="w-5 h-5 text-green-800"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                </svg>
              </span>
              <time class="block mb-2 text-[32px] font-semibold leading-none ml-12 absolute top-2 right-[420px] text-nowrap">
                March, 2019
              </time>
              <p class="mb-4 text-[32px] text-green-500 absolute top-10 right-[405px] text-nowrap font-bold">
                Fusion Partner
              </p>
            </li>
          </ol>
          <ol class="relative border-s-4 border-gray-200 dark:border-green-700 h-[200px]">
            <li class="mb-10 ms-6  w-[300px]">
              <span class="absolute flex items-center justify-center w-20 h-20 rounded-full -start-10 ring-8 ring-white dark:ring-green-700 dark:bg-green-200">
                <svg
                  class="w-5 h-5 text-green-800"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                </svg>
              </span>
              <time class="block mb-2 text-[32px] font-normal leading-none ml-12">
                June, 2020
              </time>
              <p class="mb-4 text-[32px] text-green-500 ml-12 font-bold">
                Contactless Dining
              </p>
            </li>
          </ol>
        </div>
      </HeaderContainer>
    </div>
  );
};

export default FusionTimeLine;
