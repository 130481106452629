import React from "react";
import Modal from "react-modal";
import { InlineWidget } from "react-calendly";
import  {RxCrossCircled}  from "react-icons/rx";

Modal.setAppElement("#root");

const ScheduleModal = ({ isOpen, onRequestClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Schedule a Meeting"
      className="fixed inset-0 flex items-center justify-center bg-green-950 bg-opacity-50 z-50 px-3"
      overlayClassName="Overlay"
    >
      <div className="relative bg-white rounded-lg p-8 w-full max-w-2xl">
        <button
          onClick={onRequestClose}
          className="absolute top-2 right-2 text-red-500 hover:text-green-600"
        >
          <RxCrossCircled />
        </button>
        <InlineWidget url="https://calendly.com/fusionpos/30min" />
      </div>
    </Modal>
  );
};
export default ScheduleModal;
