import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import App from "../App";
import Loading from "../asset/Loading";
import Pricing from "../pages/Pricing";
import TermsAndConditions from "../components/common/terms-and-conditions/TermsAndConditions";
import About from "../pages/About";
import Sitemap from "../pages/siteMap/SiteMap";
import DeliveryPartnershipTC from "../components/common/delivery_partnership_t_c/DeliveryPartnershipTC";
import RestaurantFranchises from "../pages/RestaurantFranchises";
import MainFaq from "../components/common/faq/MainFaq";
import Support from "../components/common/support/Support";
import UnsubscribePage from "../pages/Subscribe";
const OnlineOrderingWebsite = lazy(() =>
  import("../pages/OnlineOrderingWebsite")
);
const Area = lazy(() => import("../components/common/area/Area"));
const QROrderingSystem = lazy(() => import("../pages/QROrderingSystem"));
const ContactlessPayment = lazy(() => import("../pages/ContactlessPayment"));
const RestaurantReservationSystem = lazy(() =>
  import("../pages/RestaurantReservationSystem")
);
const ContactSales = lazy(() => import("../pages/ContactSales"));
const PointOfSales = lazy(() => import("../pages/PointOfSales"));
const OrderManagement = lazy(() => import("../pages/OrderManagement"));
const OnlineReservation = lazy(() => import("../pages/OnlineReservation"));
const DeliveryManagement = lazy(() => import("../pages/DeliveryManagement"));
const Blog = lazy(() => import("../components/main_menu/blog/Blog"));
const RoutingBlogPage = lazy(() =>
  import("../components/main_menu/blog/routing-blog-page/RoutingBlogPage")
);
const AreaRoutingPage = lazy(() =>
  import("../components/common/area/area-routing-page/AreaRoutingPage")
);
const PrivacyPolicy = lazy(() =>
  import("../components/common/privacy-policy/PrivacyPolicy")
);
const Partners = lazy(() => import("../pages/Partners"));

const Router = () => {
  return (
    <div>
      <div>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/support-service" element={<App />} />
          <Route path="/Top-Restaurant-POS-Systems" element={<App />} />
          <Route path="/payment-modes" element={<App />} />
          <Route path="/demo-video" element={<App />} />
          <Route path="*" element={<App />} />
          <Route
            path="/online-ordering-website"
            element={
              <Suspense fallback={<Loading />}>
                <OnlineOrderingWebsite />
              </Suspense>
            }
          />
        
          <Route
            path="/qr-ordering-system-for-restaurants"
            element={
              <Suspense fallback={<Loading />}>
                <QROrderingSystem />
              </Suspense>
            }
          />
          <Route
            path="/contactless-dining"
            element={
              <Suspense fallback={<Loading />}>
                <ContactlessPayment />
              </Suspense>
            }
          />
          <Route
            path="/restaurant-reservation-system"
            element={
              <Suspense fallback={<Loading />}>
                <RestaurantReservationSystem />
              </Suspense>
            }
          />
          <Route
            path="/contactus"
            element={
              <Suspense fallback={<Loading />}>
                <ContactSales />
              </Suspense>
            }
          />
          <Route
            path="/best-pos-system-for-restaurants-uk"
            element={
              <Suspense fallback={<Loading />}>
                <PointOfSales />
              </Suspense>
            }
          />
          <Route
            path="/order-management"
            element={
              <Suspense fallback={<Loading />}>
                <OrderManagement />
              </Suspense>
            }
          />
          <Route
            path="/delivery-management"
            element={
              <Suspense fallback={<Loading />}>
                <DeliveryManagement />
              </Suspense>
            }
          />
          <Route
            path="/online-reservation"
            element={
              <Suspense fallback={<Loading />}>
                <OnlineReservation />
              </Suspense>
            }
          />
          <Route
            path="/blog"
            element={
              <Suspense fallback={<Loading />}>
                <Blog />
              </Suspense>
            }
          />
          <Route
            path="/blog/:routing"
            element={
              <Suspense fallback={<Loading />}>
                <RoutingBlogPage />
              </Suspense>
            }
          />
          <Route
            path="/pricing"
            element={
              <Suspense fallback={<Loading />}>
                <Pricing />
              </Suspense>
            }
          />
          <Route
            path="/areas"
            element={
              <Suspense fallback={<Loading />}>
                <Area />
              </Suspense>
            }
          />
            <Route
            path="/subscribe"
            element={
              <UnsubscribePage /> 
            }
          />
          <Route
            path="/:routing"
            element={
              <Suspense fallback={<Loading />}>
                <AreaRoutingPage />
              </Suspense>
            }
          />
          <Route
            path="/privacy-and-policy"
            element={
              <Suspense fallback={<Loading />}>
                <PrivacyPolicy />
              </Suspense>
            }
          />
          <Route
            path="/terms-conditions"
            element={
              <Suspense fallback={<Loading />}>
                <TermsAndConditions />
              </Suspense>
            }
          />
          <Route
            path="/about-us"
            element={
              <Suspense fallback={<Loading />}>
                <About />
              </Suspense>
            }
          />
          <Route
            path="/sitemap.xml"
            element={
              <Suspense fallback={<Loading />}>
                <Sitemap />
              </Suspense>
            }
          />
          <Route
            path="/channel-partner"
            element={
              <Suspense fallback={<Loading />}>
                <Partners />
              </Suspense>
            }
          />
          <Route
            path="/delivery-terms-conditions"
            element={
              <Suspense fallback={<Loading />}>
                <DeliveryPartnershipTC />
              </Suspense>
            }
          />
          <Route
            path="/projects"
            element={
              <Suspense fallback={<Loading />}>
                <RestaurantFranchises />
              </Suspense>
            }
          />
          <Route
            path="/faq"
            element={
              <Suspense fallback={<Loading />}>
                <MainFaq />
              </Suspense>
            }
          />
          <Route
            path="/support"
            element={
              <Suspense fallback={<Loading />}>
                <Support />
              </Suspense>
            }
          />
          
        </Routes>
      </div>
    </div>
  );
};
export default Router;
